import React from "react";
import { Platform } from "react-native";
import App from "./App";
import WWWApp from "./www/WWWApp";

const PlatformHandlerRoot = () => {
  const getRootComponentByPlatform = () => {
      return <App />;
  };

  return getRootComponentByPlatform();
};

export default PlatformHandlerRoot;
