import { NavigationContainer } from "@react-navigation/native";
import * as Analytics from "expo-firebase-analytics";
import React, { useEffect, useRef, useState } from "react";
import usePushNotifications from "../hooks/usePushNotifications";
import { AuthContextT } from "../types";

type Props = {
  children: React.ReactNode;
  authContext?: AuthContextT;
};

function AnalyticsNavigationContainer({ children, authContext }: Props) {
  const navigationRef = useRef<any>();
  const routeNameRef = useRef();
  const [pendingRouteChanges, setPendingRouteChanges] = useState<
    Map<string, Object>
  >(new Map());
  const [checkPermissions] = usePushNotifications(setPendingRouteChanges);

  useEffect(() => {
    try {
      if (
        navigationRef &&
        navigationRef?.current &&
        pendingRouteChanges.size > 0
      ) {
        const routeToGoTo = pendingRouteChanges.keys().next().value;
        const navigationObject: any = {
          screen: routeToGoTo,
          // params: { navigation: navigationRef.current },
        };
        let parentRoute: string | undefined = undefined;
        if (routeToGoTo === "Home") {
          navigationRef.current.navigate("Explore");
        } else if (routeToGoTo === "Chat") {
          parentRoute = "AllMessages";
          const params = pendingRouteChanges.get(routeToGoTo) as any;
          navigationObject.params = {
            chatId: params.id,
          };
          navigationRef.current.navigate(parentRoute, navigationObject);
        } else if (routeToGoTo === "Your Rounds") {
          parentRoute = "Play Now";
          navigationRef.current.navigate(parentRoute, navigationObject);
        }
        setPendingRouteChanges(new Map());
      }
    } catch (e) {
      console.error(JSON.stringify(e));
    }
  }, [navigationRef, pendingRouteChanges]);

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() =>
        (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
      }
      onStateChange={async () => {
        try {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current.getCurrentRoute().name;

          checkPermissions(currentRouteName, authContext);
          if (previousRouteName !== currentRouteName) {
            // The line below uses the expo-firebase-analytics tracker
            // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
            // Change this line to use another Mobile analytics SDK
            Analytics.logEvent("screen_view", {
              screen_name: currentRouteName,
            });
          }

          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;
        } catch (e) {}
      }}
    >
      {children}
    </NavigationContainer>
  );
}

export default AnalyticsNavigationContainer;
