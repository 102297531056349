import { Text } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { getLinkColor } from "./StyleUtils";

const EULA_URL = "https://swingergolfapp.github.io/legal/eula.html";
const PRIVACY_POLICY_URL =
  "https://docs.google.com/document/d/e/2PACX-1vSad8ElGvrPWufxobpKM_CHbLY2gnYAUG0rgLxTD0XWgumSrGfCjX4NTgQeRNIuhOx3rOgatqqYLLsd/pub";

export function getEULALink(style: any) {
  return (
    <Text
      style={{ ...style, color: getLinkColor() }}
      onPress={() => WebBrowser.openBrowserAsync(EULA_URL)}
    >
      End User License Agreement
    </Text>
  );
}

export function getPrivacyPolicyLink(style: any) {
  return (
    <Text
      style={{ ...style, color: getLinkColor() }}
      onPress={() => WebBrowser.openBrowserAsync(PRIVACY_POLICY_URL)}
    >
      Privacy Policy
    </Text>
  );
}
