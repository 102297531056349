import { IMessage } from "react-native-gifted-chat";
import { AccountT, ChatMessageT, ChatT } from "../types";
import { convertUTCDateToLocalDate } from "./DateUtils";
import { getProfileImageURI } from "./ProfileUtils";

var id = 0;

export const getChatTitle = (
  chat: ChatT,
  accountContext: AccountT,
  allAccounts: Array<AccountT>
): string => {
  if (chat.accountIds.length > 1 && chat.title != null) {
    return chat.title!!;
  } else {
    const accountsMap = allAccounts.reduce(
        (map, account) => map.set(account.id!!, account),
        new Map()
      );
    const profileNames = chat.accountIds
      .filter((accountId) => accountId != accountContext.id)
      .map((accountId) => accountsMap.get(accountId)!!.profile?.name);
    if (profileNames.length > 1) {
      return profileNames.join(", ");
    } else if (profileNames.length == 1) {
      return profileNames[0]!!;
    } else {
      return "Chat";
    }
  }
};

export function iMessageToMessage(
  accountContext: AccountT,
  chatId: number,
  iMessage: IMessage
): ChatMessageT {
  return {
    chatId: chatId,
    fromAccountId: accountContext.id!!,
    message: iMessage.text,
    createdTime: iMessage.createdAt.toLocaleString(),
  };
}

export function messageToIMessage(
  accountContext: AccountT,
  allAccounts: Array<AccountT>,
  chatMessages: Array<ChatMessageT>
): Array<IMessage> {
  const accountsMap = allAccounts.reduce(
    (map, account) => map.set(account.id!!, account),
    new Map()
  );
  const sortedMessages = chatMessages
    .sort(
      (a, b) =>
        new Date(b.createdTime!!).getTime() -
        new Date(a.createdTime!!).getTime()
    )
    .map((message) => {
      var account = null;
      if (message.fromAccountId === accountContext.id) {
        account = accountContext;
      } else {
        account = accountsMap.get(message.fromAccountId)!!;
      }
      return {
        _id: id++,
        text: message.message,
        createdAt: convertUTCDateToLocalDate(new Date(message.createdTime!!)),
        user: {
          _id: account.id!,
          name: account.profile!.name,
          avatar: getProfileImageURI(account.profile),
        },
      };
    });

  return sortedMessages;
}
