import { ResponseType } from "expo-auth-session";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as WebBrowser from "expo-web-browser";
import * as React from "react";
import { View } from "react-native";
import { WHITE } from "../assets/styles";
import { apiIntegrationSignIn } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import SwingerButton from "./SwingerButton";
import * as Analytics from "expo-firebase-analytics";

type Props = { setLoading: Function; setErrorMessage: Function };

WebBrowser.maybeCompleteAuthSession();

export const LoginWithFacebook = ({ setLoading, setErrorMessage }: Props) => {
  const [request, response, promptAsync] = Facebook.useAuthRequest({
    expoClientId: "989305648373900",
    iosClientId: "989305648373900",
    androidClientId: "989305648373900",
    webClientId: "989305648373900",
    responseType: ResponseType.Token,
  });
  const authContext = getContext();
  const { integrationHandleResponse } = authContext;

  React.useEffect(() => {
    if (response?.type === "success") {
      setLoading(true);
      if (response.authentication?.accessToken) {
        apiIntegrationSignIn("FACEBOOK", response.authentication?.accessToken)
          .then((resp) => {
            integrationHandleResponse(resp);
            Analytics.logEvent("sign-in", {
              'integration': 'facebook'
            });
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage(
              "Something went wrong logging into Facebook.\n Please try again or try a different login method."
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [response]);

  return (
    <View>
      <SwingerButton
        mode={"outlined"}
        onPress={() => promptAsync()}
        color={WHITE}
        backgroundColor={"#2f55a4"}
        uppercase={false}
        iconName={"facebook"}
        width={250}
        marginBottom={5}
        marginTop={5}
        iconSize={20}
      >
        Continue with Facebook
      </SwingerButton>
    </View>
  );
};
