import React from "react";
import { Text, TouchableOpacity } from "react-native";
import styles, { WHITE } from "../assets/styles";
import { Icon } from "../components";
import ProfileView from "../components/ProfileView";
import { getContext } from "../services/AuthContext";
import * as Analytics from "expo-firebase-analytics";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../types";

type Props = StackScreenProps<RootStackParamList, "Profile">;

const Profile = ({ navigation }: Props) => {
  const authContext = getContext();
  const { accountContext } = authContext;

  function logout() {
    Analytics.logEvent("buttonClick", {
      buttonText: "signout",
    });
    authContext.signOut();
  }

  function settings() {
    Analytics.logEvent("buttonClick", {
      buttonText: "settings",
    });
    navigation.navigate("Settings", {});
  }

  return (
    <>
      <ProfileView
        account={accountContext}
        navigator={navigation}
        actionComponents={[
          <TouchableOpacity key={"settingsbutton"} style={styles.roundedButton} onPress={() => settings()}>
            <Icon name="cog-outline" size={20} color={WHITE} />
            <Text style={styles.textButton}>Settings</Text>
          </TouchableOpacity>,
          <TouchableOpacity
            key={"logout"}
            style={styles.roundedButton}
            onPress={() => logout()}
          >
            <Icon name="log-out-outline" size={20} color={WHITE} />
            <Text style={styles.textButton}>Logout</Text>
          </TouchableOpacity>,
        ]}
      />
    </>
  );
};

export default Profile;
