import { Camera } from "expo-camera";
import { FlashMode } from "expo-camera/build/Camera.types";
import React from "react";
import {
    Alert,
    ImageBackground,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import { Icon } from ".";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
  saveCallback: Function;
};

let camera: Camera | null;

export default function OpenCamera({ saveCallback }: Props) {
  const [startCamera, setStartCamera] = React.useState(true);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [capturedImage, setCapturedImage] = React.useState<any>(null);
  const [cameraType, setCameraType] = React.useState<"back" | "front">("back");
  const [flashMode, setFlashMode] = React.useState<FlashMode>(FlashMode.off);

  const __startCamera = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === "granted") {
      setStartCamera(true);
    } else {
      Alert.alert("Access denied");
    }
  };
  const __takePicture = async () => {
    if (camera != null) {
      const photo = await camera.takePictureAsync({base64: true});
      setPreviewVisible(true);
      //setStartCamera(false)
      setCapturedImage(photo);
    }
  };
  const __savePhoto = () => {
    saveCallback(capturedImage);
  };
  const __retakePicture = () => {
    setCapturedImage(null);
    setPreviewVisible(false);
    __startCamera();
  };
  const __handleFlashMode = () => {
    if (flashMode === "on") {
      setFlashMode(FlashMode.off);
    } else if (flashMode === "off") {
      setFlashMode(FlashMode.on);
    } else {
      setFlashMode(FlashMode.auto);
    }
  };
  const __switchCamera = () => {
    if (cameraType === "back") {
      setCameraType(Camera.Constants.Type.front);
    } else {
      setCameraType("back");
    }
  };
  return (
    <>
    {startCamera ? (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        {previewVisible && capturedImage ? (
          <CameraPreview
            photo={capturedImage}
            savePhoto={__savePhoto}
            retakePicture={__retakePicture}
          />
        ) : (
          <Camera
            type={cameraType}
            flashMode={flashMode}
            style={{ flex: 1 }}
            ref={(r) => {
              camera = r;
            }}
          >
            <View
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: "transparent",
                // flexDirection: 'column'
              }}
            >
              <View
                style={{
                  position: "absolute",
                  left: "5%",
                  top: "2%",
                  // flexDirection: 'column',
                  // justifyContent: 'space-between'
                }}
              >
                <TouchableOpacity
                  onPress={__handleFlashMode}
                  style={{
                    // borderRadius: '50%',
                    height: 25,
                    width: 25,
                  }}
                >
                  {flashMode === "off" ? (
                    <Icon color="white" name="flash-off-outline" size={25} />
                  ) : (
                    <Icon color="white" name="flash-outline" size={25} />
                  )}
                </TouchableOpacity>
              </View>
              <View
                style={{
                  position: "absolute",
                  left: "90%",
                  top: "0%",
                  marginTop: "0%",
                }}
              >
                <TouchableOpacity
                  onPress={__switchCamera}
                  style={{
                    marginTop: 20,
                    height: 25,
                    width: 25,
                  }}
                >
                  <Icon color="white" name="camera-reverse-outline" size={25} />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  position: "absolute",
                  bottom: 0,
                  flexDirection: "row",
                  flex: 1,
                  width: "100%",
                  padding: 20,
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    alignSelf: "center",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={__takePicture}
                    style={{
                      // width: 70,
                      // height: 70,
                      // bottom: 0,
                      // borderRadius: 50,
                      // backgroundColor: "#fff",
                      bottom: 25
                    }}
                  >
                    <Icon
                      name="golf-select-icon"
                      color="white"
                      size={70}
                      useCustom
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Camera>
        )}
      </View>
      <StatusBar />
    </View>
    ) : (
      <>
      <LoadingSpinner isLoading={!startCamera} />
      </>
    )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

const CameraPreview = ({ photo, retakePicture, savePhoto }: any) => {
  return (
    <View
      style={{
        backgroundColor: "transparent",
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      {/* @ts-ignore */}
      <ImageBackground
        source={{ uri: photo && photo.uri }}
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            padding: 15,
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={retakePicture}
              style={{
                width: 130,
                height: 40,

                alignItems: "center",
                borderRadius: 4,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 20,
                }}
              >
                Re-take
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={savePhoto}
              style={{
                width: 130,
                height: 40,

                alignItems: "center",
                borderRadius: 4,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: 20,
                }}
              >
                Save photo
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};
