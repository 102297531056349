import { AccountT, AuthContextT, GolfMatchT } from "./../types";

export const getProfileIdsFromGolfMatches = (golfMatches: GolfMatchT[]) => {
  let idArray: number[] = [];
  return golfMatches
    ? idArray.concat.apply(
        [],
        golfMatches.map((gm) => {
          return gm.attendingProfileIds ?? [];
        })
      )
    : [];
};

export const getMatchesWithProfiles = (
  authContext: AuthContextT,
  golfMatches: GolfMatchT[],
  profiles: AccountT[]
) => {
  let tempMatches: GolfMatchT[] = [];
  let mapProfileMap: any = {};
  golfMatches?.forEach((gm) => {
    let isHost = false;
    if (gm.hostProfileId === authContext.accountContext.id) {
      tempMatches.push({ ...gm, hostProfile: authContext.accountContext });
      isHost = true;
    } else {
      tempMatches.push({
        ...gm,
        hostProfile: profiles?.find((p) => p.id === gm.hostProfileId),
      });
    }
    const profilesForMatchArray: AccountT[] = [
      ...profiles.filter((p) => gm.attendingProfileIds?.includes(p.id!)),
    ];
    
    //Check if we need to append self to the map - If we are the host, it's already on the match obj
    if (
      profilesForMatchArray
        .map((p) => p.id)
        .includes(authContext.accountContext.id)
    ) {
      mapProfileMap[gm.id!] = [...profilesForMatchArray, authContext.accountContext];
    }
    mapProfileMap[gm.id!] = profilesForMatchArray;
  });
  return [tempMatches, mapProfileMap];
};
