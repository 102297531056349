import {
  AttachStep,
  useSpotlightTour,
} from "@stackbuilders/react-native-spotlight-tour";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { Chip } from "react-native-paper";
import styles, {
  BLACK,
  DIMENSION_HEIGHT,
  DIMENSION_WIDTH,
  DISLIKE_ACTIONS,
  LIGHT_GRAY,
  LIKE_ACTIONS,
  MAX_DIMENSION_WIDTH,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WHITE,
} from "../assets/styles";
import { getContext } from "../services/AuthContext";
import { CardItemT } from "../types";
import { getAge } from "../utils/DateUtils";
import { getHandicapString, getProfileImageURI } from "../utils/ProfileUtils";
import CachedImage from "./CachedImage";
import Icon from "./Icon";
import SideToSideAnimation from "./SideToSideAnimation";

const CardItem = ({ data, swiper }: CardItemT) => {
  // Custom styling
  // const { height, width } = Dimensions.get("window");
  const height = DIMENSION_HEIGHT;
  const width = Math.min(DIMENSION_WIDTH, MAX_DIMENSION_WIDTH);
  const { current, start } = useSpotlightTour();
  const { accountContext } = getContext();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (accountContext.profile?.isNux) {
        start();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const imageStyle = [
    {
      borderRadius: 8,
      width: width * 0.8,
      height: height * 0.48,
      margin: height * 0.02,
    },
  ];

  const nameStyle = [
    {
      flex: 1,
      color: "#363636",
      fontSize: 30,
    },
  ];

  const chipViewStyle: StyleProp<ViewStyle> = {
    flex: 1.2,
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    maxWidth: "85%",
  };

  const getTourGuideComponent = (
    swipeLeft: boolean,
    tourGuideStepNum: number
  ) => {
    const position = swipeLeft ? { right: "85%" } : { left: "92%" };
    let icon = <View></View>;
    // if (tourGuideStepNum === current) {
    if (swipeLeft) {
      icon = (
        <SideToSideAnimation leftAnimation={true}>
          {/* @ts-ignore */}
          <AttachStep index={tourGuideStepNum}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                opacity: tourGuideStepNum === current ? 1 : 0,
              }}
            >
              <Icon name="caret-back" size={50} color={SECONDARY_COLOR} />
              <Icon
                name="hand-pointer-o"
                size={50}
                color={SECONDARY_COLOR}
                useFA={true}
              />
            </View>
          </AttachStep>
        </SideToSideAnimation>
      );
    } else {
      icon = (
        <SideToSideAnimation leftAnimation={false}>
          {/* @ts-ignore */}
          <AttachStep index={tourGuideStepNum}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                opacity: tourGuideStepNum === current ? 1 : 0,
              }}
            >
              <Icon
                name="hand-pointer-o"
                size={50}
                color={SECONDARY_COLOR}
                useFA={true}
              />
              <Icon name="caret-forward" size={50} color={SECONDARY_COLOR} />
            </View>
          </AttachStep>
        </SideToSideAnimation>
      );
      // }
    }
    //This should only show during it's tour guide step
    //Currently swipe left is 2nd step and swipe right is the 3rd
    return (
      <View
        style={{
          position: "absolute",
          bottom: "50%",
          ...position,
        }}
      >
        <View>{icon}</View>
      </View>
    );
  };

  return (
    <View
      style={{
        backgroundColor: WHITE,
        borderRadius: 8,
        alignItems: "center",
        margin: 10,
        elevation: 1,
        shadowOpacity: 0.05,
        shadowRadius: 10,
        shadowColor: BLACK,
        shadowOffset: { height: 0, width: 0 },
      }}
    >
      {/* IMAGE */}
      {getProfileImageURI(data!!.profile) && (
        <CachedImage
          uri={getProfileImageURI(data!!.profile)}
          style={imageStyle}
          resizeMode="cover"
        />
      )}
      <View
        style={{
          height: height * 0.25,
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {/* NAME */}
        <View style={{ flex: 0.8 }}>
          <Text style={nameStyle}>{data.profile!!.name}</Text>
        </View>
        {data.profile && (
          <>
            <View style={chipViewStyle}>
              <Chip mode="outlined" style={styles.chipStyle}>
                {getHandicapString(data.profile.handicap!!)} Handicap
              </Chip>

              {data.profile.dob && (
                <Chip mode="outlined" style={styles.chipStyle}>
                  Age {getAge(data.profile.dob)}
                </Chip>
              )}

              {data.profile.ride && (
                <Chip mode="outlined" style={styles.chipStyle}>
                  🛺 {data.profile.ride}
                </Chip>
              )}

              {data.profile.alcohol && (
                <Chip mode="outlined" style={styles.chipStyle}>
                  🍻 {data.profile.alcohol}
                </Chip>
              )}
            </View>

            {/* DESCRIPTION */}
            {/* {data.profile.description && (
            <Text style={styles.descriptionCardItem}>
              {data.profile.description}
            </Text>
          )} */}
          </>
        )}

        {/* ACTIONS */}
        {swiper.current != null && (
          <View style={styles.actionsCardItem}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                swiper.current?.swipeRight();
              }}
            >
              <Icon
                name="golf-select-icon"
                color={LIKE_ACTIONS}
                size={25}
                useCustom
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                swiper.current?.swipeLeft();
              }}
            >
              <Icon name="close" color={DISLIKE_ACTIONS} size={25} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {getTourGuideComponent(true, 0)}
      {getTourGuideComponent(false, 1)}
    </View>
  );
};

export default CardItem;
