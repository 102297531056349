import React from "react";
import { Image, Text, View } from "react-native";
import styles from "../assets/styles";
import { AccountT, GolfMatchT } from "../types";
import { parseDate } from "../utils/DateUtils";
import { getProfileImageURI } from "../utils/ProfileUtils";
import CachedImage from "./CachedImage";

type Props = {
  data: GolfMatchT;
  myView: boolean;
  profileData: Array<AccountT | undefined> | undefined;
};

export default function PlayOffer({ profileData, data, myView }: Props) {
  return (
    <View style={{ ...styles.containerCardItem, paddingBottom: 20 }}>
      <View style={{ flexDirection: "row" }}>
        {profileData &&
          profileData.map((profile) => {
            return (
              <CachedImage
                key={profile?.id}
                uri={getProfileImageURI(profile!!.profile)}
                style={styles.avatar}
              />
            );
          })}
      </View>
      <View>
        {!myView ? (
          <Text>
            {data.hostProfile?.profile?.name} needs{" "}
            {data.playersNeeded - (data.attendingProfileIds!!.length - 1)}{" "}
            players!
          </Text>
        ) : (
          <Text style={{textAlign: "center"}}>
            {profileData
              ?.map((profile) => {
                if (profile?.profile?.name.indexOf(" ") != -1) {
                  return profile?.profile?.name.split(" ")[0];
                }
                return profile?.profile?.name;
              })
              .join(", ")}{" "}
            Attending
          </Text>
        )}
        <Text>
          {parseDate(data.time)} @ {data.courseName}
        </Text>
      </View>
    </View>
  );
}
