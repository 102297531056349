import { uuid4 } from "@sentry/utils";
import React from "react";
import { Text, View } from "react-native";
import { Card, Divider } from "react-native-paper";
import styles from "../assets/styles";
import { MessageT } from "../types";
import CachedImage from "./CachedImage";

const avatarStyle = {
  borderRadius: 30,
  width: 60,
  height: 60,
  // marginRight: 20,
  marginVertical: 15,
};

const Message = ({ lastMessage, name, images, unread, isMatch }: MessageT) => {
  const getAvatars = () => {
    let zIndex = 100;
    return images.map((imageUrl: string, index: number) => {
      let style: any = { zIndex, elevation: zIndex };
      zIndex = zIndex - 1;
      if (index != 0) {
        style = { ...style, position: "relative", marginLeft: -45 };
      }
      return (
        <View style={style} key={imageUrl ?? uuid4()}>
          <CachedImage key={imageUrl} uri={imageUrl} style={avatarStyle} />
        </View>
      );
    });
  };

  const getLastMessage = () => {
    if (lastMessage == undefined) {
      if (isMatch) {
        return "Start planning your upcoming round!";
      } else {
        return "Say hi to your new connection!";
      }
    }
    return lastMessage;
  };

  return (
    <Card>
      <View style={styles.containerMessage}>
        <View style={{ flexDirection: "row", marginRight: 20 }}>
          {getAvatars()}
        </View>
        <View>
          <Text style={unread ? { fontWeight: "bold" } : {}}>{name}</Text>
          <Text style={styles.message}>{getLastMessage()}</Text>
        </View>
      </View>
      <Divider />
    </Card>
  );
};

export default Message;
