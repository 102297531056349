import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect } from "react";
import ProfileEdit from "../components/ProfileEdit";
import { RootStackParamList } from "../types";

type Props = StackScreenProps<RootStackParamList>;

const UpdateProfile = ({ navigation }: Props) => {

  useEffect(() => {
    navigation.setOptions({
      headerBackTitleVisible: false,
    });
  }, []);

  return (
    <>
      <ProfileEdit
        headerText="Update your profile"
        onUpdateComplete={() => navigation.goBack()}
        showLegal={false}
        buttonText={"Save"}
      />
    </>
  );
};

export default UpdateProfile;
