import { FontAwesome5 } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import styles, { PRIMARY_COLOR } from "../assets/styles";
import { getContext } from "../services/AuthContext";
import { AccountT, RootStackParamList } from "../types";
import { getHandicapString } from "../utils/ProfileUtils";

type Props = {
  account: AccountT;
  navigator: StackNavigationProp<RootStackParamList, any>;
};

const ProfileItem = ({ account, navigator }: Props) => {
  const profile = account.profile!!;
  const authContext = getContext();
  const { accountContext } = authContext;
  const age = new Date().getFullYear() - new Date(profile.dob!!).getFullYear();

  return (
    <View style={styles.containerProfileItem}>
      {/* Edit profile button */}
      {accountContext.id!! == account.id!! && (
        <View style={{ flex: 1, flexDirection: "row-reverse", zIndex: 100 }}>
          <View style={{ position: "absolute", marginTop: 30 }}>
            <TouchableOpacity
              onPressIn={() => navigator.navigate("Update Profile", {})}
            >
              <FontAwesome5 name="user-edit" size={20} color={PRIMARY_COLOR} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      <Text style={styles.name}>{profile.name}</Text>

      <Text style={styles.descriptionProfileItem}>
        {profile.gender} - {age}
      </Text>

      <View style={styles.info}>
        <Text style={styles.iconProfile}>Handicap</Text>
        <Text style={styles.infoContent}>
          {getHandicapString(profile.handicap!!)}
        </Text>
      </View>

      <View style={styles.info}>
        <Text style={styles.iconProfile}>🛺 Preference</Text>
        <Text style={styles.infoContent}>{profile.ride}</Text>
      </View>

      <View style={styles.info}>
        <Text style={styles.iconProfile}>🍻 Preference</Text>
        <Text style={styles.infoContent}>{profile.alcohol}</Text>
      </View>

      {/* <View style={styles.info}>
      <Text style={styles.iconProfile}>
        <Icon name="calendar" size={12} color={DARK_GRAY} />
      </Text>
      <Text style={styles.infoContent}>{info4}</Text>
    </View> */}
    </View>
  );
};

export default ProfileItem;
