import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View, TouchableOpacity } from "react-native";
import { Card, Checkbox, Divider } from "react-native-paper";
import styles, {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WHITE,
} from "../assets/styles";
import { Icon } from "../components";
import CachedImage from "../components/CachedImage";
import GolfView from "../components/GolfView";
import LoadingSpinner from "../components/LoadingSpinner";
import { apiGetAllMatchedProfiles } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { AccountT, RootStackParamList } from "../types";
import { getProfileImageURI } from "../utils/ProfileUtils";

type Props = StackScreenProps<RootStackParamList, "Invite Friends">;

const Friends = ({ route, navigation }: Props) => {
  const [isLoadingg, setIsLoadingg] = useState<boolean>(false);
  const [friends, setFriends] = useState<Array<AccountT>>([]);
  const [selectedIdsToImage, setSelectedIdsToImage] = useState<
    Map<number, string>
  >(new Map());
  const {
    numPlayersNeeded,
    selectedFriends,
    setSelectedFriends,
    alreadyAttendingFriends,
  } = route.params;
  const authContext = getContext();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      navigation.setOptions({ headerBackTitle: "Back" });
      setIsLoadingg(true);
      setSelectedIdsToImage(selectedFriends);
      apiGetAllMatchedProfiles(authContext).then((resp) => {
        const retMatches = resp.data as AccountT[];
        setFriends(
          retMatches.sort((a, b) =>
            a.profile!!.name.localeCompare(b.profile!!.name)
          )
        );
        setIsLoadingg(false);
      });
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  function onPress(item: AccountT) {
    if (!alreadyAttendingFriends?.has(item.id!!)) {
      if (selectedIdsToImage.has(item.id!!)) {
        selectedIdsToImage.delete(item.id!!);
      } else if (selectedIdsToImage.size < numPlayersNeeded) {
        selectedIdsToImage.set(item.id!!, getProfileImageURI(item.profile) ?? "");
      }
      setSelectedIdsToImage(new Map(selectedIdsToImage));
    }
  }

  function onClickSend() {
    setSelectedFriends(selectedIdsToImage);
    navigation.goBack();
  }

  return (
    <>
      {!isLoadingg ? (
        <GolfView
          flex={1}
          disableKeyboardOverride={true}
          containerOverride={{
            ...styles.containerMessages,
            backgroundColor: WHITE,
            flexGrow: 1,
          }}
        >
          {friends.length > 0 ? (
            <View style={{ flex: 1 }}>
              <View style={{ flex: 0.9 }}>
                <FlatList
                  data={friends}
                  keyExtractor={(item, index) => item.id?.toString()!!}
                  renderItem={({ item }) => {
                    return (
                      <TouchableOpacity
                        disabled={alreadyAttendingFriends?.has(item.id!!)}
                        onPress={() => onPress(item)}
                      >
                        <Card>
                          <View style={styles.containerMessage}>
                            <Checkbox.Android
                              disabled={alreadyAttendingFriends?.has(item.id!!)}
                              color={SECONDARY_COLOR}
                              status={
                                selectedIdsToImage.has(item.id!!) ||
                                alreadyAttendingFriends?.has(item.id!!)
                                  ? "checked"
                                  : "unchecked"
                              }
                              onPressIn={() => onPress(item)}
                            />
                            <CachedImage
                              key={item!!.id}
                              uri={getProfileImageURI(item!!.profile)}
                              style={{ ...styles.avatar, marginLeft: 10 }}
                            />
                            <Text>{item.profile!!.name}</Text>
                          </View>
                          <Divider />
                        </Card>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
              <View
                style={{
                  flex: 0.1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 18 }}>
                  {selectedIdsToImage.size}/{numPlayersNeeded} Swingers Selected
                </Text>
                <TouchableOpacity onPress={() => onClickSend()}>
                  <Icon color={PRIMARY_COLOR} name={"send"} size={25} />
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View
              style={{
                marginTop: "10%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "90%",
              }}
            >
              <Text style={styles.infoMessage}>This seems empty...</Text>
              <Text style={styles.infoMessage}>
                Swing to find new golf buddies!
              </Text>
            </View>
          )}
        </GolfView>
      ) : (
        <>
          <LoadingSpinner isLoading={true} />
        </>
      )}
    </>
  );
};

export default Friends;
