import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";

export async function setStorageValue(key: string, value: string) {
  if (Platform.OS === "web") {
    localStorage.setItem(key, value);
  } else {
    await SecureStore.setItemAsync(key, value);
  }
}

export async function deleteStorageKey(key: string) {
  if (Platform.OS === "web") {
    localStorage.removeItem(key);
  } else {
    return await SecureStore.deleteItemAsync(key);
  }
}

export async function getStorageValue(key: string): Promise<string | null> {
  if (Platform.OS === "web") {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  } else {
    return await SecureStore.getItemAsync(key);
  }
}
