const dateConfig : Intl.DateTimeFormatOptions = {
    // year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    hour12: true,
  };

export function parseDate(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(Date.parse(date));
  }
  if (date.toDateString() === new Date().toDateString()) {
    return "Today @ " + formatAMPM(date);
  }
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (date.toDateString() === tomorrow.toDateString()) {
    return "Tomorrow @ " + formatAMPM(date);
  }
  return formatDateNoTime(date) + " " + formatAMPM(date);
}

export function getAge(dateNum: number) {
  var today = new Date();
  var birthDate = new Date(dateNum);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function formatDateNoTime(date : Date) {
  return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
}

function formatAMPM(date : Date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var minStr = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minStr + ' ' + ampm;
  return strTime;
}

export function convertUTCDateToLocalDate(date : Date) {
  var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  return newDate;   
}
