import {
  ColorValue,
  // Platform,
  // PlatformColor,
} from "react-native";

export function getLinkColor(): ColorValue {
    // return Platform.OS === "ios" ? PlatformColor("linkColor") : "blue";
    return 'blue';
}
