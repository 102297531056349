import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import {
  BigShouldersDisplay_100Thin,
  BigShouldersDisplay_300Light,
  BigShouldersDisplay_400Regular,
  BigShouldersDisplay_500Medium,
  BigShouldersDisplay_600SemiBold,
  BigShouldersDisplay_700Bold,
  BigShouldersDisplay_800ExtraBold,
  BigShouldersDisplay_900Black,
} from "@expo-google-fonts/big-shoulders-display";
import {
  LibreBaskerville_400Regular,
  LibreBaskerville_400Regular_Italic,
  LibreBaskerville_700Bold,
} from "@expo-google-fonts/libre-baskerville";
import { useEffect, useState } from "react";
import { Asset } from "expo-asset";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          IcoMoon: require("../assets/icons/fonts/icomoon.ttf"),
          BigShouldersDisplay_100Thin,
          BigShouldersDisplay_300Light,
          BigShouldersDisplay_400Regular,
          BigShouldersDisplay_500Medium,
          BigShouldersDisplay_600SemiBold,
          BigShouldersDisplay_700Bold,
          BigShouldersDisplay_800ExtraBold,
          BigShouldersDisplay_900Black,
          LibreBaskerville_400Regular,
          LibreBaskerville_400Regular_Italic,
          LibreBaskerville_700Bold,
        });
        await Asset.fromModule(
          require("../assets/images/golfer-placeholder.png")
        ).downloadAsync();
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
