import React, { useEffect, useState } from "react";
import { Keyboard, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Divider, TextInput } from "react-native-paper";
import styles from "../assets/styles";
import { useGooglePlacesAutoComplete } from "../hooks/useGooglePlacesAutocomplete";
import { getContext } from "../services/AuthContext";
import SwingerTextInput from "./SwingerTextInput";

const GOOGLE_API_KEY = "AIzaSyBAnX3eu-31TwNPVAyH9u_AC6S7xRTYIhc";
let typeAheadKey = 1;

type Props = {
  courseCallback: Function;
};

const GolfCourseSelection = ({ courseCallback }: Props) => {
  const { accountContext } = getContext();
  const [course, setCourse] = useState("");
  const [queriedCourse, setQueriedCourse] = useState("");
  const location =
    accountContext.profile!!.location?.latitude +
    "," +
    accountContext.profile!!.location?.longitude;
  const { data: placesData, isLoading: isPlacesLoading } =
    useGooglePlacesAutoComplete(queriedCourse, location, GOOGLE_API_KEY);
  const [dropdownEnabled, setDropdownEnabled] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (dropdownEnabled) {
        let searchTerm = course;
        if (course != "") {
          searchTerm = course.toLowerCase().includes("golf")
            ? course
            : `golf ${course}`;
          setQueriedCourse(searchTerm);
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [course, dropdownEnabled]);

  return (
    <>
      <SwingerTextInput
        label="Search for Courses"
        value={course}
        onChangeText={(text: any) => setCourse(text)}
        style={{ ...styles.textInput, marginTop: 10 }}
        onFocus={() => {
          setDropdownEnabled(true);
        }}
        onBlur={() => {
          setDropdownEnabled(false);
        }}
        leftIcon={<TextInput.Icon name="magnify" color={"grey"} />}
      />
      <View
        style={{
          zIndex: 1,
        }}
      >
        {dropdownEnabled && course != "" && placesData && placesData.length > 0 && (
          <>
            <View
              style={{
                position: "absolute",
                backgroundColor: "white",
                borderColor: "grey",
                borderRadius: 10,
                borderWidth: 0.5,
                padding: 10,
              }}
            >
              {placesData
                .map((item, idx) => {
                  if (
                    item == undefined ||
                    item.structured_formatting == undefined ||
                    item.structured_formatting.main_text == undefined
                  ) {
                    return null;
                  }
                  return (
                    <View key={typeAheadKey++} style={{ marginTop: 5 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setCourse(item.structured_formatting.main_text);
                          courseCallback(item.structured_formatting.main_text);
                          Keyboard.dismiss();
                        }}
                      >
                        <Text>{item.structured_formatting.main_text}</Text>
                        {item.structured_formatting.secondary_text && (
                          <Text style={{ color: "grey" }}>
                            {item.structured_formatting.secondary_text}
                          </Text>
                        )}
                      </TouchableOpacity>
                      {idx != placesData.length - 1 && (
                        <Divider style={{ marginTop: 5 }} />
                      )}
                    </View>
                  );
                })
                .filter((item) => item != null)}
            </View>
          </>
        )}
      </View>
    </>
  );
};

export default GolfCourseSelection;
