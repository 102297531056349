import React from "react";
import { Animated, View } from "react-native";

type Props = {
  children: React.ReactNode;
  leftAnimation: boolean;
};

const SideToSideAnimation = ({ children, leftAnimation }: Props) => {
  // This is the value you will set and your arrow will automatically be placed
  const [arrowValue, setArrowValue] = React.useState<number>(0); // From 0 to 1 for example

  // This is your animation
  // It is the value you have to change to make your arrow move
  const [animation, setAnimation] = React.useState(new Animated.Value(0));

  // This is an interpolation of your animation
  // See this as a black box, it allows you to output value (and ranges of values)
  // based on the value of your animation
  const animateArrowPosition = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });

  // This is where everthing happens automatically
  React.useEffect(() => {
    moveArrowTo(arrowValue);
  }, [arrowValue]); // Anytime 'arrowValue' changes, execute the 'moveArrowTo' function

  const moveArrowTo = (newArrowValue: number) => {
    Animated.timing(animation, {
      toValue: newArrowValue,
      duration: 500,
      useNativeDriver: false,
    }).start(() => {
      setArrowValue(newArrowValue === 0 ? 1 : 0);
      // Do something (or nothing) once the animation finished
    });
  };

  const getStyle = () => {
    if (leftAnimation) {
      return { left: animateArrowPosition };
    } else {
      return { right: animateArrowPosition };
    }
  };

  return (
    <Animated.View style={getStyle()}>
      <View>{children}</View>
    </Animated.View>
  );
};

export default SideToSideAnimation;
