import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { Card } from "react-native-paper";
import styles, { DARK_GRAY } from "../assets/styles";
import { Icon } from "../components";
import PlayOffer from "../components/PlayOffer";
import { GolfMatchT } from "../types";
import GolfView from "./GolfView";
import LoadingSpinner from "./LoadingSpinner";
import SwingerButton from "./SwingerButton";
import SwingerModal from "./SwingerModal";

interface Props {
  matches: Array<GolfMatchT>;
  myView?: boolean;
  title: string;
  setModalVisible: Function;
  modalVisible: boolean;
  setSelectedMatch: Function;
  modal: React.ReactNode;
  showCreateMatch?: boolean;
  navigation: StackNavigationProp<any, string>;
  matchIdToProfiles?: any;
  isLoading: boolean;
}

const GolfMatchList = ({
  matchIdToProfiles,
  navigation,
  showCreateMatch = false,
  modal,
  modalVisible,
  setModalVisible,
  setSelectedMatch,
  title,
  matches,
  myView = false,
  isLoading,
}: Props) => {
  function onClick(item: GolfMatchT) {
    setSelectedMatch(item);
    setModalVisible(true);
  }

  function navigateCreateMatch() {
    navigation.navigate("Setup a Round");
  }

  return (
    <View style={{flex: 1}}>
      {!isLoading ? (
        <GolfView containerOverride={{ flex: 1 }}>
          <View style={styles.top}>
            <Text style={styles.title}>{title}</Text>
            <TouchableOpacity>
              <Icon name="ellipsis-vertical" color={DARK_GRAY} size={20} />
            </TouchableOpacity>
          </View>
          <SwingerModal visible={modalVisible} setVisible={setModalVisible}>
            {modal}
          </SwingerModal>
          {showCreateMatch || matches.length === 0 ? (
            <>
              {matches.length === 0 ? (
                <Card>
                  <View
                    style={{ alignItems: "center", marginTop: 10, padding: 20 }}
                  >
                    <Text style={styles.infoMessage}>This seems empty...</Text>
                    <Text style={styles.infoMessage}>Create a match to play with your new friends!</Text>
                  </View>
                </Card>
              ) : (
                <></>
              )}
              <SwingerButton onPress={() => navigateCreateMatch()}>
                Create New Match
              </SwingerButton>
            </>
          ) : (
            <></>
          )}
          <FlatList
            numColumns={1}
            data={matches.sort(
              (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
            )}
            keyExtractor={(item, index) => index.toString()}
            ListFooterComponent={<View style={{height: 250}}/>}
            renderItem={({ item }) => (
              <View style={{height: 160 }}>
                <TouchableOpacity onPress={() => onClick(item)}>
                  <PlayOffer
                    profileData={
                      matchIdToProfiles != undefined
                        ? matchIdToProfiles[item.id!!]
                        : [item.hostProfile]
                    }
                    myView={myView}
                    data={item}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </GolfView>
      ) : (
        <LoadingSpinner isLoading={isLoading} />
      )}
    </View>
  );
};

export default GolfMatchList;
