import { Platform } from "expo-modules-core";
import React, { ReactElement, ReactNode } from "react";
import {
  ImageBackground,
  Keyboard,
  KeyboardAvoidingView,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import styles, { MAX_DIMENSION_WIDTH } from "../assets/styles";
import { WebAspectRatioView } from "./WebAspectRatioView";

interface Props {
  children: React.ReactNode;
  containerOverride?: StyleProp<ViewStyle>;
  flex?: number;
  keyboardVerticalOffset?: number;
  disableKeyboardOverride?: boolean;
  enableScrollView?: boolean;
  centerView?: boolean;
}

export default function GolfView({
  children,
  containerOverride,
  flex = 0,
  keyboardVerticalOffset = 0,
  disableKeyboardOverride = false,
  enableScrollView = false,
  centerView = true,
}: Props): ReactElement {
  const maybeKeyboardDismiss = (children: ReactNode) => {
    if (!enableScrollView) {
      <TouchableWithoutFeedback
        style={{ flex: flex }}
        onPress={Keyboard.dismiss}
        accessible={false}
      >
        {children}
      </TouchableWithoutFeedback>;
    }
    return <>{children}</>;
  };

  const maybeKeyboardAvoidingView = (children: ReactNode) => {
    if (disableKeyboardOverride) {
      return (
        <View style={containerOverride ?? styles.container}>{children}</View>
      );
    }
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={containerOverride ?? styles.container}
        keyboardVerticalOffset={keyboardVerticalOffset}
      >
        {maybeKeyboardDismiss(children)}
      </KeyboardAvoidingView>
    );
  };

  const maybeScrollView = (children: ReactNode) => {
    if (!enableScrollView) {
      return <>{children}</>;
    }
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: centerView ? "center" : undefined,
        }}
        keyboardShouldPersistTaps="handled"
      >
        {children}
      </ScrollView>
    );
  };

  return (
    // @ts-ignore
    <ImageBackground
      source={require("../assets/images/golf-bg.png")}
      // resizeMode="cover"
      style={{ ...styles.golfBg, flex: 1 }}
    >
      <>
        {Platform.OS === "web" ? (
          <WebAspectRatioView>{children}</WebAspectRatioView>
        ) : (
          maybeKeyboardAvoidingView(maybeScrollView(children))
        )}
      </>
    </ImageBackground>
  );
}
