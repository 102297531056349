import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import {
  createStackNavigator,
  StackHeaderTitleProps,
} from "@react-navigation/stack";
import { SpotlightTourProvider } from "@stackbuilders/react-native-spotlight-tour";
import AppLoading from "expo-app-loading";
import * as Analytics from "expo-firebase-analytics";
import * as Updates from "expo-updates";
import React, { ReactElement, useState } from "react";
import { Image, Text, View } from "react-native";
import { enableScreens } from "react-native-screens";
import Toast, { BaseToast } from "react-native-toast-message";
import { QueryClient, QueryClientProvider } from "react-query";
import { BLACK, DARK_GRAY, PRIMARY_COLOR, WHITE } from "./assets/styles";
import { Icon } from "./components";
import AnalyticsNavigationContainer from "./components/AnalyticsNavigationContainer";
import GolfView from "./components/GolfView";
import TabBarIcon from "./components/TabBarIcon";
import useCachedResources from "./hooks/useCachedResources";
import useSwingerUpdates from "./hooks/useSwingerUpdates";
import useTourSteps from "./hooks/useTourSteps";
import Chat from "./screens/Chat";
import Friends from "./screens/Friends";
import Home from "./screens/Home";
import Messages from "./screens/Messages";
import CreateAccount from "./screens/nux/CreateAccount";
import CreateProfile from "./screens/nux/CreateProfile";
import UploadImage from "./screens/nux/UploadImage";
import PlayNow from "./screens/PlayNow";
import Profile from "./screens/Profile";
import BlockedUsers from "./screens/settings/BlockedUsers";
import DeleteAccount from "./screens/settings/DeleteAccount";
import Legal from "./screens/settings/Legal";
import Settings from "./screens/settings/Settings";
import SetupPlay from "./screens/SetupPlay";
import SignIn from "./screens/SignIn";
import UpdatePhotos from "./screens/UpdatePhotos";
import UpdateProfile from "./screens/UpdateProfile";
import UserInfo from "./screens/UserInfo";
import UserSelect from "./screens/UserSelect";
import YourRounds from "./screens/YourRounds";
import {
  apiFeatureToggles,
  apiGetAccount,
  apiRefreshToken,
  apiSignIn,
} from "./services/ApiService";
import { ctx as AuthContext } from "./services/AuthContext";
import LoggedInContext from "./services/LoggedInContext";
import { deleteStorageKey, getStorageValue, setStorageValue } from "./services/StorageService";
import WebsocketContext from "./services/WebsocketContext";
import { AccountT, AuthContextT, RootStackParamList } from "./types";
import { shouldAddImage, shouldCreateProfile } from "./utils/ProfileUtils";

const queryClient = new QueryClient();
const Stack = createStackNavigator<RootStackParamList>();
const Tab = createBottomTabNavigator();
enableScreens(false);

const App = () => {
  const [pendingNotifications, setPendingNotifications] = useState<
    number | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<ReactElement | undefined>(
    undefined
  );
  useSwingerUpdates();

  const [state, dispatch] = React.useReducer(
    (
      prevState: any,
      action: {
        type: any;
        token?: any;
        userAccount?: AccountT;
        refreshToken?: any;
        expirationTime?: any;
      }
    ) => {
      switch (action.type) {
        case "UPDATE_ACCOUNT":
          return {
            ...prevState,
            userAccount: action.userAccount,
            isLoading: false,
          };
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            expirationTime: action.expirationTime,
            refreshToken: action.refreshToken,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            expirationTime: action.expirationTime,
            refreshToken: action.refreshToken,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            expirationTime: null,
            refreshToken: null,
            userToken: null,
            userAccount: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      userAccount: null,
      refreshToken: null,
      expirationTime: null,
    }
  );

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      apiFeatureToggles(authContext).then((resp) => {
        const errorMessage = resp.data.errorMessage;
        if (errorMessage != null) {
          setErrorMessage(
            <GolfView>
              <Text style={{ textAlign: "center" }}>{errorMessage}</Text>
            </GolfView>
          );
        }
      });
      let userToken;
      let refreshToken;
      let expirationTime;
      let userAccountJson;
      try {
        userToken = await getStorageValue("userToken");
        refreshToken = await getStorageValue("refreshToken");
        expirationTime = await getStorageValue("expirationTime");
        userAccountJson = (await getStorageValue(
          "userAccount"
        )) as string;
        if (expirationTime != null) {
          expirationTime = parseInt(expirationTime);
        }

        dispatch({
          type: "RESTORE_TOKEN",
          token: userToken,
          refreshToken: refreshToken,
          expirationTime: expirationTime,
        });
        if (userAccountJson != null) {
          dispatch({
            type: "UPDATE_ACCOUNT",
            userAccount: JSON.parse(userAccountJson),
          });
        }
      } catch (e) {
        console.error("Issue with restoring state", e);
        return;
      }

    };
    bootstrapAsync();
    setAnalyticProperties();
  }, []);

  React.useEffect(() => {
    if (
      state.userAccount != null &&
      state.userAccount.profile != null &&
      state.userAccount.profile.images != null &&
      state.userAccount.profile.images[0] != null
    ) {
      Image.prefetch(state.userAccount.profile.images[0]);
    }
  }, [state.userAccount]);

  const authContext: AuthContextT = React.useMemo(
    () => ({
      signIn: async (
        username: string,
        password: string,
        callback: Function
      ) => {
        apiSignIn(username, password)
          .then((response) => {
            handleAuthResponse(response);
          })
          .catch((error) => {
            callback("Username or password were incorrect.");
          });
      },
      integrationHandleResponse: async (response: any) => {
        handleAuthResponse(response);
      },
      signOut: () => {
        deleteStorageKey("userToken");
        deleteStorageKey("refreshToken");
        deleteStorageKey("userAccount");
        deleteStorageKey("expirationTime");
        dispatch({ type: "SIGN_OUT" });
      },
      setAccount: (account: AccountT) => {
        //TODO This should create a new obj
        //don't want to break everything rn tho
        setStorageValue("userAccount", JSON.stringify(account));
        dispatch({ type: "UPDATE_ACCOUNT", userAccount: account });
      },
      restoreToken: (
        userToken: string,
        refreshToken: string,
        expirationTime: number
      ) => {
        dispatch({
          type: "RESTORE_TOKEN",
          token: userToken,
          refreshToken: refreshToken,
          expirationTime: expirationTime,
        });
      },
      doRefreshToken: async (refreshToken: string) => {
        const response = await apiRefreshToken(refreshToken).catch((error) => {
          authContext.signOut();
        });
        return handleAuthResponse(response);
      },
      accountContext: state.userAccount,
      token: state.userToken,
      refreshToken: state.refreshToken,
      expirationTime: state.expirationTime,
    }),
    [state.userAccount, state.userToken]
  );

  const handleAuthResponse = (response: any): string => {
    var expirationTime = new Date().getTime();
    expirationTime = expirationTime + response.data.expires_in * 1000;

    setStorageValue("userToken", response.data.access_token);
    setStorageValue("refreshToken", response.data.refresh_token);
    setStorageValue("expirationTime", expirationTime.toString());
    const context = {
      token: response.data.access_token,
      refreshToken: response.data.refresh_token,
      expirationTime: expirationTime,
    };
    dispatch({
      ...context,
      type: "SIGN_IN",
    });
    apiGetAccount(context.token).then((response) => {
      authContext.setAccount(response.data);
    });
    return response.data.access_token;
  };

  const setAnalyticProperties = async () => {
    try {
      await Analytics.setUserProperty(
        "ota_update_date",
        Updates.createdAt?.toUTCString() ?? null
      );
      await Analytics.setUserProperty(
        "ota_update_is_fallback",
        String(Updates.isEmergencyLaunch)
      );
      await Analytics.setUserProperty("ota_update_id", Updates.updateId);
    } catch (e) {
      console.error("Issue setting analytic properties", e);
    }
  };

  const [tourSteps] = useTourSteps({ authContext });

  // Load the icon font before using it
  const fontsLoaded = useCachedResources();
  if (!fontsLoaded) {
    return <AppLoading />;
  }

  function messageNavigator(): ReactElement {
    return (
      <>
        <Stack.Navigator>
          <Stack.Screen name="Messages" component={Messages} />
          <Stack.Screen name="Chat" component={Chat} />
        </Stack.Navigator>
      </>
    );
  }

  function yourRoundsNavigator(): ReactElement {
    return (
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Your Rounds" component={YourRounds} />
      </Stack.Navigator>
    );
  }

  function playNowNavigator(): ReactElement {
    const Tab = createMaterialTopTabNavigator();
    return (
      <Tab.Navigator>
        <Tab.Screen name="Play Now" component={PlayNow} />
        <Tab.Screen name="Your Rounds" component={yourRoundsNavigator} />
      </Tab.Navigator>
    );
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={authContext}>
          <SpotlightTourProvider
            steps={tourSteps}
            overlayColor={"gray"}
            overlayOpacity={0.36}
          >
            {errorMessage != undefined ? (
              errorMessage
            ) : (
              <>
                {state.userAccount == null ||
                state.userAccount.profile == null ||
                shouldCreateProfile(state.userAccount.profile) ||
                shouldAddImage(state.userAccount.profile) ? (
                  <AnalyticsNavigationContainer>
                    <Stack.Navigator>
                      <Stack.Screen
                        options={{ headerShown: false }}
                        name="Sign-In"
                        component={SignIn}
                      />
                      <Stack.Screen
                        name="Create Account"
                        component={CreateAccount}
                      />
                      <Stack.Screen
                        name="Create Your Profile"
                        component={CreateProfile}
                      />
                      <Stack.Screen
                        name="Upload Photos"
                        component={UploadImage}
                      />
                    </Stack.Navigator>
                  </AnalyticsNavigationContainer>
                ) : (
                  <WebsocketContext>
                    <LoggedInContext
                      setPendingNotifications={setPendingNotifications}
                      setErrorMessage={setErrorMessage}
                    >
                      <AnalyticsNavigationContainer authContext={authContext}>
                        {/* <NavigationContainer> */}
                        <Stack.Navigator>
                          <Stack.Screen
                            name="Tab"
                            //headerShown: false
                            options={{
                              animationEnabled: false,
                              headerTitleAlign: "left",
                              headerTitle: (props: StackHeaderTitleProps) => {
                                return (
                                  <Text
                                    style={{
                                      fontSize: 35,
                                      fontFamily:
                                        "LibreBaskerville_400Regular_Italic",
                                      color: "#076652",
                                      letterSpacing: 1,
                                      textAlign: "left",
                                    }}
                                  >
                                    Swinger
                                  </Text>
                                );
                              },
                            }}
                          >
                            {() => (
                              <Tab.Navigator
                                tabBarOptions={{
                                  showLabel: false,
                                  activeTintColor: PRIMARY_COLOR,
                                  inactiveTintColor: DARK_GRAY,
                                  labelStyle: {
                                    fontSize: 14,
                                    textTransform: "uppercase",
                                    paddingTop: 10,
                                  },
                                  style: {
                                    backgroundColor: WHITE,
                                    borderTopWidth: 0,
                                    marginBottom: 0,
                                    shadowOpacity: 0.05,
                                    shadowRadius: 10,
                                    shadowColor: BLACK,
                                    shadowOffset: { height: 0, width: 0 },
                                    overflow: 'hidden'
                                  },
                                }}
                              >
                                <Tab.Screen
                                  name="Home"
                                  component={Home}
                                  options={{
                                    tabBarIcon: ({ focused }) => (
                                      <TabBarIcon
                                        focused={focused}
                                        iconName="golf-swing"
                                        iconSize={20}
                                        text="Swing"
                                        customIcon={true}
                                      />
                                    ),
                                  }}
                                />

                                <Tab.Screen
                                  name="Play Now"
                                  component={playNowNavigator}
                                  options={{
                                    tabBarIcon: ({ focused }) => (
                                      <TabBarIcon
                                        focused={focused}
                                        iconName="golf-play-now"
                                        iconSize={20}
                                        text="Play Now"
                                        customIcon={true}
                                        tourGuideStepNum={2}
                                      />
                                    ),
                                  }}
                                />

                                <Tab.Screen
                                  name="AllMessages"
                                  component={messageNavigator}
                                  options={{
                                    tabBarBadge: pendingNotifications,
                                    tabBarIcon: ({ focused }) => (
                                      <TabBarIcon
                                        focused={focused}
                                        iconName="chatbubble"
                                        text="Chat"
                                        tourGuideStepNum={3}
                                      />
                                    ),
                                  }}
                                />
                                <Tab.Screen
                                  name="Profile"
                                  component={Profile}
                                  options={{
                                    tabBarIcon: ({ focused }) => (
                                      <TabBarIcon
                                        focused={focused}
                                        iconName="person"
                                        text="Profile"
                                        tourGuideStepNum={4}
                                      />
                                    ),
                                  }}
                                />
                              </Tab.Navigator>
                            )}
                          </Stack.Screen>
                          <Stack.Screen
                            name="Setup a Round"
                            component={SetupPlay}
                          />
                          <Stack.Screen
                            name="Invite Friends"
                            component={Friends}
                          />
                          <Stack.Screen
                            name="User Select"
                            component={UserSelect}
                          />
                          <Stack.Screen name="User Info" component={UserInfo} />
                          <Stack.Screen
                            name="Update Profile"
                            component={UpdateProfile}
                          />
                          <Stack.Screen name="Settings" component={Settings} />
                          <Stack.Screen
                            name="Blocked Users"
                            component={BlockedUsers}
                          />
                          <Stack.Screen name="Legal" component={Legal} />
                          <Stack.Screen
                            name="Delete Account"
                            component={DeleteAccount}
                          />
                          <Stack.Screen
                            name="Update Photos"
                            component={UpdatePhotos}
                          />
                        </Stack.Navigator>
                      </AnalyticsNavigationContainer>
                    </LoggedInContext>
                  </WebsocketContext>
                )}
              </>
            )}
          </SpotlightTourProvider>
        </AuthContext.Provider>
      </QueryClientProvider>
      {/* } */}
      {/* <LoadingSpinner isLoading={initialLoad} /> */}
      <Toast
        config={{
          success: (props) => (
            <BaseToast
              {...props}
              text2NumberOfLines={2}
              style={{ borderLeftColor: "#69C779" }}
              renderLeadingIcon={() => {
                return (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      name="golf-select-icon"
                      size={40}
                      color={"#69C779"}
                      useCustom
                    />
                  </View>
                );
              }}
            />
          ),
        }}
      />
    </>
  );
};

export default App;
