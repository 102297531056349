import React, { ReactNode } from "react";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
  isLoading: boolean;
  children: ReactNode;
};

const LoadingComponent = ({ isLoading, children }: Props) => {
  return <>{!isLoading ? children :         <>
    <LoadingSpinner isLoading={true} />
  </> }</>;
};

export default LoadingComponent;
