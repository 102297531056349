import { FontAwesome, Ionicons } from "@expo/vector-icons";
import React from "react";
import { CustomIcon } from "../services/IconService";
import { IconT } from "../types";

const Icon = ({ color, name, size, style, useCustom = false, useFA = false, }: IconT) => {
  if (useFA) {
    return <FontAwesome name={name} size={size} color={color} style={style} />
  } else if(!useCustom) {
    return <Ionicons name={name} size={size} color={color} style={style} />;
  } else {
    return <CustomIcon name={name} size={size} color={color} style={style} />
  }
};

export default Icon;
