import { HeaderBackButton, StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity } from "react-native";
import styles, { PRIMARY_COLOR, WHITE } from "../assets/styles";
import { Icon } from "../components";
import ProfileView from "../components/ProfileView";
import { apiBlockAccount, apiReportAccount } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { getChatContext } from "../services/ChatContext";
import { AccountT, RootStackParamList } from "../types";
import Toast from "react-native-toast-message";
import * as Analytics from "expo-firebase-analytics";

type Props = StackScreenProps<RootStackParamList, "User Info">;

const UserInfo = ({ route, navigation }: Props) => {
  const [hasBlocked, setHasBlocked] = useState(false);
  const [hasReported, setHasReported] = useState(false);
  const [account, setAccount] = useState<AccountT | undefined>(undefined);

  const authContext = getContext();
  const { getAccountsOrLoad, refreshChats } = getChatContext();
  const { accountId, isBlocked } = route.params;

  useEffect(() => {
    navigation.setOptions({
      headerBackTitleVisible: false,
      headerTitle: account?.profile?.name,
    });
  }, []);

  useEffect(() => {
    getAccountsOrLoad([accountId]).then((accounts: Array<AccountT>) => {
      setAccount(accounts[0]);
    });
  }, [accountId]);

  function report() {
    Analytics.logEvent("buttonClick", {
      buttonText: "reportUser",
    });
    apiReportAccount(accountId, authContext).then((resp) => {
      setHasReported(true);
      Toast.show({
        type: "success",
        text1: "Reported User",
        text2:
          "Reported " +
          account?.profile?.name +
          "! \nWe will review this accounts content and take action.",
      });
    });
  }

  function block() {
    Analytics.logEvent("buttonClick", {
      buttonText: "blockUser",
    });
    apiBlockAccount(accountId, true, authContext).then((resp) => {
      setHasBlocked(true);
      Toast.show({
        type: "success",
        text1: "Blocked User",
        text2:
          "Blocked " +
          account?.profile?.name +
          "! \nYou will no longer see this user in messsages or matches.",
      });
      refreshChats();
      navigation.setOptions({
        headerLeft: () => (
          <HeaderBackButton
            onPress={() => {
              navigation.navigate("Messages");
            }}
          />
        ),
      });
    });
  }

  function unblock() {
    Analytics.logEvent("buttonClick", {
      buttonText: "blockUser",
    });
    apiBlockAccount(accountId, false, authContext).then((resp) => {
      setHasBlocked(true);
      refreshChats();
    });
  }

  function getButtonStyle(hasBlocked: boolean) {
    const style = hasBlocked
      ? styles.roundedButtonDisabled
      : styles.roundedButton;
    return { ...style, backgroundColor: PRIMARY_COLOR };
  }

  return (
    <>
      {account && (
        <ProfileView
          navigator={navigation}
          account={account}
          actionComponents={[
            <TouchableOpacity
              key="blockbutton"
              style={getButtonStyle(hasBlocked)}
              onPress={() => block()}
              disabled={hasBlocked}
            >
              <Icon name="md-remove-circle-outline" size={20} color={WHITE} />
              <Text style={styles.textButton}>{isBlocked? "Unblock" : "Block"}</Text>
            </TouchableOpacity>,
            <TouchableOpacity
              key="flagbutton"
              style={getButtonStyle(hasBlocked)}
              onPress={() => report()}
              disabled={hasReported}
            >
              <Icon name="flag-outline" size={20} color={WHITE} />
              <Text style={styles.textButton}>Report</Text>
            </TouchableOpacity>,
          ]}
        />
      )}
    </>
  );
};

export default UserInfo;
