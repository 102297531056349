import React, { ReactElement } from "react";
import { Text } from "react-native";

interface Props {
  text: string;
}

export default function MastersSubHeader({text}: Props): ReactElement {
  return (
    <>
      <Text
        style={{
          marginTop: 20,
          fontSize: 20,
          fontFamily: "LibreBaskerville_400Regular",
          color: "#076652",
          textAlign: "center",
          letterSpacing: 1,
        }}
      >
        {text}
      </Text>
    </>
  );
}
