import * as React from "react";
import { AuthContextT } from "../types";

const ctx = React.createContext<AuthContextT | undefined>(undefined);

function getContext() : AuthContextT {
  const c = React.useContext(ctx);
  if (c === undefined)
    throw new Error("useCtx must be inside a Provider with a value");
  
  return c;
}

function getConsumer() {
  return ctx.Consumer;
}

export { ctx, getContext, getConsumer };
