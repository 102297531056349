import * as React from "react";
import { ChatContextT } from "../types";

const cContext = React.createContext<ChatContextT | undefined>(undefined);

function getChatContext(): ChatContextT {
  const c = React.useContext(cContext);
  if (c === undefined)
    throw new Error("useCtx must be inside a Provider with a value");

  return c;
}

function getConsumer() {
    return cContext.Consumer;
  }

export { cContext, getChatContext };
