import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import * as React from "react";
import { Image, Text, View } from "react-native";
import { BLACK, WHITE } from "../assets/styles";
import { apiIntegrationSignIn } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import SwingerButton from "./SwingerButton";
import * as Analytics from "expo-firebase-analytics";

type Props = { setLoading: Function; setErrorMessage: Function };

WebBrowser.maybeCompleteAuthSession();

export const LoginWithGoogle = ({ setLoading, setErrorMessage }: Props) => {
  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId:
      "989671331915-3814cbb603qsjn5d6qjsvetm0hk3t8bg.apps.googleusercontent.com",
    iosClientId:
      "989671331915-fq7r08888igdjud9f36a5k0u59dufjsc.apps.googleusercontent.com",
    androidClientId:
      "989671331915-g2vi05060gsno1f5ruikh0jp8a5uic73.apps.googleusercontent.com",
    webClientId: '989671331915-b507lsfmnr29ias7lpufpsjpeoans8bc.apps.googleusercontent.com',
    scopes: [
      "openid",
      "profile",
      "email",
      "https://www.googleapis.com/auth/user.gender.read",
      "https://www.googleapis.com/auth/user.birthday.read",
    ],
  });
  const authContext = getContext();
  const { integrationHandleResponse } = authContext;

  React.useEffect(() => {
    if (response?.type === "success") {
      setLoading(true);
      if (response.authentication?.accessToken) {
        apiIntegrationSignIn("GOOGLE", response.authentication?.accessToken)
          .then((resp) => {
            integrationHandleResponse(resp);
            Analytics.logEvent("sign-in", {
              'integration': 'google'
            });
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage(
              "Something went wrong logging into Google.\n Please try again or try a different login method."
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [response]);

  return (
    <SwingerButton
      disabled={!request}
      onPress={() => promptAsync()}
      color={BLACK}
      backgroundColor={WHITE}
      uppercase={false}
      mode={"outlined"}
      width={250}
      marginBottom={5}
      marginTop={5}
      buttonName={"LoginWithGoogle"}
    >
      <Image
        source={require("../assets/icons/png/icons8-google-48.png")}
        fadeDuration={0}
        resizeMode={"contain"}
        style={{height: 20, width: 20}}
      />
      <View style={{width: 5}} />
      <Text>Continue with Google</Text>
     </SwingerButton>
  );
};
