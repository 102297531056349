import { FontAwesome5 } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import {
  ImageBackground,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import styles, { PRIMARY_COLOR } from "../assets/styles";
import { ProfileItem } from "../components";
import { getContext } from "../services/AuthContext";
import { AccountT, RootStackParamList } from "../types";
import { getProfileImageSource } from "../utils/ProfileUtils";
import { WebAspectRatioView } from "./WebAspectRatioView";

type Props = {
  account: AccountT;
  actionComponents: Array<React.ReactNode>;
  navigator: StackNavigationProp<RootStackParamList, any>;
};

const ProfileView = ({ account, actionComponents, navigator }: Props) => {
  const authContext = getContext();
  const { accountContext } = authContext;

  return (
    // @ts-ignore
    <ImageBackground
      source={require("../assets/images/golf-bg.png")}
      style={styles.golfBg}
    >
      <WebAspectRatioView>
      <ScrollView style={styles.containerProfile}>
        {/* @ts-ignore */}
        <ImageBackground
          source={getProfileImageSource(account.profile)}
          style={styles.photo}
        >
          {/* Edit photo button */}
          {accountContext.id!! == account.id!! && (
            <View
              style={{ flex: 1, flexDirection: "row-reverse", zIndex: 100 }}
            >
              <View
                style={{ position: "absolute", marginTop: 10, marginRight: 10 }}
              >
                <TouchableOpacity
                  onPress={() => navigator.navigate("Update Photos", {})}
                >
                  <FontAwesome5 name="images" size={20} color={PRIMARY_COLOR} />
                </TouchableOpacity>
              </View>
            </View>
          )}
        </ImageBackground>
        <ProfileItem account={account} navigator={navigator} />
        <View style={styles.actionsProfile}>
          {actionComponents.map((it) => it)}
        </View>
      </ScrollView>
      </WebAspectRatioView>
    </ImageBackground>
  );
};

export default ProfileView;
