import { StackScreenProps } from "@react-navigation/stack";
import * as React from "react";
import ImageEdit from "../../components/ImageEdit";
import { RootStackParamList } from "../../types";
import * as Analytics from "expo-firebase-analytics";

type Props = StackScreenProps<RootStackParamList>;

const UploadImage = ({ navigation }: Props) => {
  return (
    <>
      <ImageEdit
        navigator={navigation}
        headerText={"Pick an image for your profile"}
        noImagesErrorMessage={
          "Snap or select a photo to complete your account setup."
        }
        buttonText={"Continue"}
        onUpdateComplete={() => Analytics.logEvent("sign_up")}
      />
    </>
  );
};

export default UploadImage;
