import { StackScreenProps } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import React, { useEffect } from "react";
import { Image, Keyboard, Platform, Text, View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { GRAY } from "../assets/styles";
import GolfView from "../components/GolfView";
import LoadingSpinner from "../components/LoadingSpinner";
import { LoginWithApple } from "../components/LoginWithApple";
import { LoginWithFacebook } from "../components/LoginWithFacebook";
import { LoginWithGoogle } from "../components/LoginWithGoogle";
import LogoBig from "../components/LogoBig";
import SwingerButton from "../components/SwingerButton";
import SwingerTextInput from "../components/SwingerTextInput";
import { getContext } from "../services/AuthContext";
import { RootStackParamList } from "../types";
import { getEULALink, getPrivacyPolicyLink } from "../utils/LegalUtils";
import { EMAIL_REGEX, nextRouteBasedOnProfile } from "../utils/ProfileUtils";

type Props = StackScreenProps<RootStackParamList>;

function SignIn({ navigation }: Props) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const authContext = getContext();
  const { accountContext, signIn } = authContext;

  useEffect(() => {
    if (accountContext != null) {
      const nextRoute = nextRouteBasedOnProfile(accountContext.profile);
      if (nextRoute != null) {
        navigation.navigate(nextRoute);
      }
    }
  }, [accountContext]);

  useEffect(() => {
    if (errorMessage != null && errorMessage.length > 0) {
      Analytics.logEvent("SignInError", { errorMessage });
    }
  }, [errorMessage]);

  const handleSignIn = () => {
    if (EMAIL_REGEX.test(username) && password.length > 0) {
      signIn(username, password, setErrorMessage);
      Analytics.logEvent("sign-in", {
        'integration': 'none'
      });
    } else if (!EMAIL_REGEX.test(username)) {
      setErrorMessage("Username is not a valid username");
    } else {
      setErrorMessage("Password is not valid");
    }
  };

  const navigateCreateScreen = () => {
    navigation.navigate("Create Account");
  };

  return (
    <GolfView enableScrollView={true}>
      {!isLoading ? (
        <>
          <LogoBig />
          {errorMessage.length > 0 && (
            <Text style={{ color: "red", textAlign: "center" }}>
              {errorMessage}
            </Text>
          )}
          <SwingerTextInput
            label="Email"
            value={username}
            onChangeText={setUsername}
          />
          <SwingerTextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            onSubmitEditing={handleSignIn}
          />
          <SwingerButton width={250} onPress={() => handleSignIn()}>
            Sign In
          </SwingerButton>
          <SwingerButton width={250} onPress={() => navigateCreateScreen()}>
            Create an Account
          </SwingerButton>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <View style={{ width: 100, height: 1, backgroundColor: GRAY }} />
            <View>
              <Text style={{ width: 50, textAlign: "center" }}>or</Text>
            </View>
            <View style={{ width: 100, height: 1, backgroundColor: GRAY }} />
          </View>
          <View
            style={{ flexDirection: "column", justifyContent: "space-evenly" }}
          >
            <LoginWithFacebook
              setErrorMessage={setErrorMessage}
              setLoading={setIsLoading}
            />
            <LoginWithGoogle
              setErrorMessage={setErrorMessage}
              setLoading={setIsLoading}
            />
            {Platform.OS == "ios" && (
              <LoginWithApple
                setErrorMessage={setErrorMessage}
                setLoading={setIsLoading}
              />
            )}
          </View>
          <Text style={{ textAlign: "center", marginTop: 5, paddingLeft: 5, paddingRight: 5 }}>
            By using Swinger, you agree to the {getEULALink({})} and the{" "}
            {getPrivacyPolicyLink({})}.
          </Text>
        </>
      ) : (
        <>
          <LoadingSpinner isLoading={true} />
        </>
      )}
    </GolfView>
  );
}

export default SignIn;
