import React, { useEffect, useState } from "react";
import { Image, ImageResizeMode } from "react-native";
import { getCachedImage } from "../utils/CacheUtils";

type Props = {
  uri?: string;
  style: any;
  resizeMode?: ImageResizeMode;
};

const CachedImage = (props: Props) => {
  const [uri, setUri] = useState<any>(undefined);
  const placeholder = require("../assets/images/golfer-placeholder.png");

  useEffect(() => {
    if (props.uri) {
      getCachedImage(props.uri).then((uri) => setUri({ uri: uri }));
    } else {
      setUri(placeholder);
    }
  }, []);

  return (
    <>
      <Image
        defaultSource={placeholder}
        style={props.style}
        source={uri}
        resizeMode={props.resizeMode}
      />
    </>
  );
};

export default CachedImage;
