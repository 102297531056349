import React from "react";
import { Modal, Pressable, TouchableWithoutFeedback, View } from "react-native";

type Props = {
  children: React.ReactNode;
  visible: boolean;
  setVisible: Function;
};

export default function SwingerModal({ children, visible, setVisible }: Props) {
  return (
    <Modal
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        setVisible(!visible);
      }}
    >
      <Pressable
        style={{
          backgroundColor: "rgba(1, 1, 1, 0.2)",
          flex: 1,
        }}
        onPress={(event) => {
          if (event.target != event.currentTarget) {
            setVisible(false);
          }
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 22,
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                margin: 20,
                backgroundColor: "white",
                borderRadius: 20,
                padding: 35,
                alignItems: "center",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,
              }}
            >
              {children}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </Pressable>
    </Modal>
  );
}
