import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { GRAY } from "../assets/styles";
import FriendList from "../components/FriendList";
import { getChatContext } from "../services/ChatContext";
import { AccountT, RootStackParamList } from "../types";
import { Entypo } from "@expo/vector-icons";

type Props = StackScreenProps<RootStackParamList, "User Select">;

const UserSelect = ({ route, navigation }: Props) => {
  const { title, accountIds } = route.params;
  const [loadedAccounts, setLoadedAccounts] = useState<Array<AccountT>>([]);
  const { getAccountsOrLoad } = getChatContext();

  useEffect(() => {
    navigation.setOptions({
      title: title,
      headerBackTitleVisible: false,
    });
    getAccountsOrLoad(accountIds).then((accounts: Array<AccountT>) => {
      setLoadedAccounts(accounts);
    });
  }, []);

  const handleClickUser = (account: AccountT) => {
    navigation.navigate("User Info", {
      accountId: account.id!!,
      isBlocked: false,
    });
  };

  return (
    <>
      <FriendList
        friends={loadedAccounts}
        onClickUser={handleClickUser}
        rightComponent={
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Entypo name="chevron-small-right" size={24} color="black" />
          </View>
        }
      />
    </>
  );
};

export default UserSelect;
