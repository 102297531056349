import React, { ReactElement } from "react";
import { Text } from "react-native";
import MastersSubHeader from "./MastersSubHeader";

interface Props {
  text: string;
  subtext?: string;
}

export default function MastersHeader({
  text,
  subtext,
}: Props): ReactElement {
  return (
    <>
      <Text
        style={{
          fontSize: 40,
          fontFamily: "LibreBaskerville_400Regular_Italic",
          color: "#076652",
          textAlign: "center",
          letterSpacing: 8,
        }}
      >
        {text}
      </Text>
      {subtext ? (
        <MastersSubHeader text={subtext} />
      ) : (
        <></>
      )}
    </>
  );
}
