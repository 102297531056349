import {
    Align,
    Position,
    TourStep,
    useSpotlightTour
} from "@stackbuilders/react-native-spotlight-tour";
import { Text, View, TouchableOpacity } from "react-native";
import { GRAY, SECONDARY_COLOR } from "../assets/styles";
import { Icon } from "../components";
import { apiCreateOrUpdateProfile } from "../services/ApiService";
import { AuthContextT } from "../types";
import * as Analytics from "expo-firebase-analytics";

const SWIPE_LEFT_TOUR_GUIDE_TEXT =
  "👋 Welcome to Swinger!\n\nThis is your 'swing' page! Swing on other golfers to pair on with new golfing friends!\n\nTo swing, you can swipe left to 'like' a user! \n\n Click the right arrow to continue the tutorial.";
const SWIPE_RIGHT_TOUR_GUIDE_TEXT =
  "You can swipe right to 'dislike' this user!";
const GOLF_MATCHES_TOUR_GUIDE_TEXT =
  "This tab will have golf matches!\n\nYou can setup a golf match that all your new friends can see, or you can join a match that your friends have setup!";
const CHAT_TOUR_GUIDE_TEXT =
  "This tab will have chat threads once you've matched with golfers!\n\n You can message your new friends individually, or if you've joined a golf match you will be have a group chat!";
const PROFILE_TOUR_GUIDE_TEXT =
  "This tab will have your profile information!\n\n Now it's time to start swinging!";

type Props = {
  authContext: AuthContextT;
};

export default function useTourSteps({authContext} : Props) {

  const { accountContext, token, setAccount } = authContext;

  const clearNUXFlag = async () => {
    const profile = accountContext.profile;
    if (profile && profile.isNux) {
      Analytics.logEvent("tutorial_begin");
      profile.isNux = false;
      apiCreateOrUpdateProfile(profile, authContext).then((response) => {
        const profile = response.data as any;
        accountContext.profile = profile;
        setAccount(accountContext);
      });
    }
  };

  const getModalComponent = (firstStep: boolean, lastStep: boolean, text: string) => {
    const { next, previous, stop } = useSpotlightTour();
    const stopTour = () => {
      Analytics.logEvent("tutorial_complete");
      stop();
    }
    return (
      <View
        style={{
          backgroundColor: "white",
          borderColor: SECONDARY_COLOR,
          borderWidth: 2,
          borderRadius: 9,
          justifyContent: "center",
          padding: 20,
          maxWidth: "90%",
          elevation: 100,
        }}
      >
        <Text>{text}</Text>
        <View
          style={{
            marginTop: 10,
            alignContent: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TouchableOpacity onPressIn={previous}>
            <Icon
              name="caret-back"
              size={24}
              color={firstStep ? GRAY : SECONDARY_COLOR}
            />
          </TouchableOpacity>
          <TouchableOpacity onPressIn={stopTour}>
            <Icon name="stop" size={24} color={SECONDARY_COLOR} />
          </TouchableOpacity>
          <TouchableOpacity onPressIn={lastStep ? stopTour : next}>
            <Icon name="caret-forward" size={24} color={SECONDARY_COLOR} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const tourSteps: TourStep[] = [
    {
      before: () => clearNUXFlag(),
      alignTo: Align.SCREEN,
      position: Position.BOTTOM,
      render: () => getModalComponent(true, false, SWIPE_LEFT_TOUR_GUIDE_TEXT),
    },
    {
      alignTo: Align.SCREEN,
      position: Position.BOTTOM,
      render: () => getModalComponent(false, false, SWIPE_RIGHT_TOUR_GUIDE_TEXT),
    },
    {
      alignTo: Align.SCREEN,
      position: Position.TOP,
      render: () => getModalComponent(false, false, GOLF_MATCHES_TOUR_GUIDE_TEXT),
    },
    {
      alignTo: Align.SCREEN,
      position: Position.TOP,
      render: () => getModalComponent(false, false, CHAT_TOUR_GUIDE_TEXT),
    },
    {
      alignTo: Align.SCREEN,
      position: Position.TOP,
      render: () => getModalComponent(false, true, PROFILE_TOUR_GUIDE_TEXT),
    },
  ];

  return [tourSteps];
}
