import { View } from "react-native";
import { BLACK, WHITE } from "../assets/styles";
import useAppleSignIn from "../hooks/useAppleSignIn";
import SwingerButton from "./SwingerButton";
import * as Analytics from "expo-firebase-analytics";

type Props = { setLoading: Function; setErrorMessage: Function };

export const LoginWithApple = ({ setLoading, setErrorMessage }: Props) => {
  const [login] = useAppleSignIn({ setLoading, setErrorMessage });

  const signIn = () => {
    login();
    Analytics.logEvent("sign-in", {
      'integration': 'apple'
    });
  }

  return (
    <View>
      <SwingerButton
        onPress={() => signIn()}
        color={WHITE}
        backgroundColor={BLACK}
        uppercase={false}
        mode={"outlined"}
        iconName={"apple"}
        marginBottom={5}
        marginTop={5}
        width={250}
      >
        Sign in with Apple
      </SwingerButton>
    </View>
  );
};
