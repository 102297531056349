import React from "react";
import * as AppleAuthentication from "expo-apple-authentication";
import { AppleAuthenticationFullName } from "expo-apple-authentication";
import { apiIntegrationSignIn } from "../services/ApiService";
import { getContext } from "../services/AuthContext";

type Props = {
  setLoading: Function;
  setErrorMessage: Function;
};

const getName = (fullName?: AppleAuthenticationFullName) => {
  if (
    fullName != null &&
    fullName.familyName != null &&
    fullName.givenName != null
  ) {
    return fullName.givenName + " " + fullName.familyName;
  }
  return undefined;
};

const useAppleSignIn = ({ setLoading, setErrorMessage }: Props) => {
  const authContext = getContext();
  const { integrationHandleResponse } = authContext;
  const login = async () => {
    setLoading(true);
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      // signed in
      apiIntegrationSignIn(
        "APPLE",
        credential.authorizationCode!!,
        credential.identityToken!!,
        getName(credential.fullName ?? undefined)
      )
        .then((resp) => {
          integrationHandleResponse(resp);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(`Apple Login Error: ${JSON.stringify(error)}`);
          setErrorMessage(
            "Something went wrong logging into Apple. \n Please try again or try a different login method."
          );
        });
    } catch (e: any) {
      setLoading(false);
      if (e.code === "ERR_CANCELED") {
        // handle that the user canceled the sign-in flow
      } else {
        setErrorMessage(
          "Something went wrong logging into Apple.\n Please try again or try a different login method."
        );
        console.error(JSON.stringify(e));
      }
    }
  };

  return [login];
};

export default useAppleSignIn;
