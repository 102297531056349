import React, { useState } from "react";
import { Text, View } from "react-native";
import SwingerButton from "../../components/SwingerButton";
import SwingerTextInput from "../../components/SwingerTextInput";
import { apiDeleteAccount } from "../../services/ApiService";
import { getContext } from "../../services/AuthContext";

const DeleteAccount = () => {
  const [confirm, setConfirm] = useState("");

  const authContext = getContext();

  const deleteAccount = () => {
    apiDeleteAccount(authContext)
      .then((resp) => {
        if (resp.data == true) {
          authContext.signOut();
        }
      });
      // Handle if apple invalid?
      // .catch((e: any) => {
      //   if(e.response.status === 400 && e.response.data && e.response.data.message == "") {
          
      //   }
      // });
  };

  return (
    <View style={{ padding: 20 }}>
      <Text>
        Deleting your account is irrevisble. After deleting your account, and
        you want to use Swinger Golf again, you will need to re-create your
        account. This means you will also be removing any connections, matches,
        or other data related to your account.{"\n"}
      </Text>
      <Text>
        To delete your account, please type your DELETE MY ACCOUNT in the text
        box below and click submit:
      </Text>
      <SwingerTextInput
        label={"Confirm Deletion"}
        value={confirm}
        onChangeText={(text: any) => {
          setConfirm(text);
        }}
        style={{ marginTop: 10 }}
      />
      <SwingerButton
        disabled={confirm.toUpperCase() != "DELETE MY ACCOUNT"}
        onPress={() => deleteAccount()}
      >
        Delete Account
      </SwingerButton>
    </View>
  );
};

export default DeleteAccount;
