import { Entypo } from "@expo/vector-icons";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { Card, Divider } from "react-native-paper";
import styles, { WHITE } from "../../assets/styles";
import GolfView from "../../components/GolfView";
import { RootStackParamList } from "../../types";

type Props = StackScreenProps<RootStackParamList, "Settings">;

type SettingsOpt = {
  title: string;
  route: keyof RootStackParamList;
};

const SETTINGS_OPTS: Array<SettingsOpt> = [
  {
    title: "Blocked Users",
    route: "Blocked Users",
  },
  {
    title: "Legal",
    route: "Legal",
  },
  {
    title: "Delete Account",
    route: "Delete Account"
  }
];

const Settings = ({ route, navigation }: Props) => {

  useEffect(() => {
    navigation.setOptions({
      headerBackTitleVisible: false,
    });
  }, []);

  const handleClick = (settingOpt: SettingsOpt) => {
    navigation.navigate(settingOpt.route);
  };

  return (
    <GolfView
      flex={1}
      disableKeyboardOverride={true}
      containerOverride={{
        ...styles.containerMessages,
        backgroundColor: WHITE,
        flexGrow: 1,
      }}
    >
      <View style={{ flex: 1 }}>
        <FlatList
          data={SETTINGS_OPTS}
          keyExtractor={(item, index) => item.title}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity onPress={() => handleClick(item)}>
                <Card>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      height: 100,
                    }}
                  >
                    <Text>{item.title}</Text>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Entypo
                        name="chevron-small-right"
                        size={24}
                        color="black"
                      />
                    </View>
                  </View>
                  <Divider />
                </Card>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </GolfView>
  );
};

export default Settings;
