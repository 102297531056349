import { ProfileItemT } from "../types";

export function shouldCreateProfile(profile?: ProfileItemT) {
  return (
    profile === undefined ||
    profile.dob == undefined ||
    profile.alcohol == undefined ||
    profile.handicap == undefined ||
    profile.ride == undefined ||
    profile.gender == undefined
  );
}

export function shouldAddImage(profile?: ProfileItemT) {
  return (
    profile != undefined &&
    (profile.images == undefined || profile.images.length === 0)
  );
}

export function nextRouteBasedOnProfile(
  profile?: ProfileItemT
): "Create Your Profile" | "Upload Photos" | null {
  if (shouldCreateProfile(profile)) {
    return "Create Your Profile";
  }
  if (shouldAddImage(profile)) {
    return "Upload Photos";
  }
  return null;
}

export function getHandicapString(handicap: number): string {
  try {
    if (handicap > 0) {
      return `+${handicap}`;
    } else if (handicap < 0) {
      return `${handicap}`;
    } else {
      return handicap.toString();
    }
  } catch (e) {
    console.error(JSON.stringify(e));
    return "0";
  }
}

export function getProfileImageURI(profile?: ProfileItemT) : string | undefined {
  if(profile != null && profile.images != null && profile.images.length > 0) {
   return profile.images[0];
  }
  return undefined;
}

const placeholder = require("../assets/images/golfer-placeholder.png");

export function getProfileImageSource(profile?: ProfileItemT) : any {
  const uri = getProfileImageURI(profile);
  if(uri != undefined) {
    return {uri : uri};
  } else {
    return placeholder;
  }
}



export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const PASSWORD_REGEX = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
);
