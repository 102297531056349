import React from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { Card, Checkbox, Divider } from "react-native-paper";
import styles, { SECONDARY_COLOR, WHITE } from "../assets/styles";
import { AccountT } from "../types";
import { getProfileImageURI } from "../utils/ProfileUtils";
import CachedImage from "./CachedImage";
import GolfView from "./GolfView";

type Props = {
  friends: Array<AccountT>;
  onClickUser: Function;
  footer?: React.ReactNode;
  leftSelector?: React.ReactNode;
  rightComponent?: React.ReactNode;
  rowDisabled?: Function;
};

const FriendList = ({
  friends,
  onClickUser,
  footer,
  rowDisabled,
  leftSelector = null,
  rightComponent,
}: Props) => {
  return (
    <GolfView
      flex={1}
      disableKeyboardOverride={true}
      containerOverride={{
        ...styles.containerMessages,
        backgroundColor: WHITE,
        flexGrow: 1,
      }}
    >
      {friends.length > 0 && (
        <View style={{ flex: 1 }}>
          <View style={{ flex: 0.9 }}>
            <FlatList
              data={friends}
              keyExtractor={(item, index) => item.id?.toString()!!}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    disabled={rowDisabled ? rowDisabled() : false}
                    onPress={() => onClickUser(item)}
                  >
                    <Card>
                      <View style={styles.containerMessage}>
                        {leftSelector && leftSelector}
                        <CachedImage
                          key={item!!.id}
                          uri={getProfileImageURI(item!!.profile)}
                          style={{ ...styles.avatar, marginLeft: 10 }}
                        />
                        <Text>{item.profile!!.name}</Text>
                        {rightComponent && rightComponent}
                      </View>
                      <Divider />
                    </Card>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
          {footer && footer}
        </View>
      )}
    </GolfView>
  );
};

export default FriendList;
