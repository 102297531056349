import React from "react";
import { Linking, Text, View } from "react-native";
import styles from "../assets/styles";
import SwingerButton from "./SwingerButton";

type Props = {
  permissionType: string;
  errorMessage: string;
  errorColor?: string;
};

const PromptSettingsMessage = ({
  permissionType,
  errorMessage,
  errorColor,
}: Props) => {
  return (
    <View>
      <Text style={{ ...styles.infoMessage, color: errorColor }}>
        {errorMessage}
        {"\n"}
        {"\n"}
        Please go to your settings and enable Swinger to have {
          permissionType
        }{" "}
        permissions.
      </Text>
      <SwingerButton onPress={() => Linking.openSettings()}>
        Go to Settings
      </SwingerButton>
    </View>
  );
};

export default PromptSettingsMessage;
