import { StackScreenProps } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import * as Location from "expo-location";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Image, Linking, Text, View } from "react-native";
import CardStack, { Card } from "react-native-card-stack-swiper";
import Toast from "react-native-toast-message";
import styles, {
  DIMENSION_WIDTH,
  DISLIKE_ACTIONS,
  LIKE_ACTIONS,
  MAX_DIMENSION_WIDTH,
} from "../assets/styles";
import { CardItem, Icon } from "../components";
import GolfView from "../components/GolfView";
import LoadingSpinner from "../components/LoadingSpinner";
import SwingerButton from "../components/SwingerButton";
import useAppState from "../hooks/useAppState";
import {
  apiCreateOrUpdateProfile,
  apiDislikeProfile,
  apiGetNext10Matches,
  apiLikeProfile,
} from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { getChatContext } from "../services/ChatContext";
import { AccountT, RootStackParamList, SwipeResponseT } from "../types";
import { isJwtValid } from "../utils/JwtUtil";
import { uuid4 } from "@sentry/utils";
import SwipeIcon from "../components/SwipeIcon";
import PromptSettingsMessage from "../components/PromptSettingsMessage";

type Props = StackScreenProps<RootStackParamList>;

const NUM_RETURNED_FROM_API = 10;

const Home = ({ navigation }: Props) => {
  let swiper: any = useRef(null);
  const [currentMatches, setCurrentMatches] = useState<AccountT[]>([]);
  const [nextMatches, setNextMatches] = useState<AccountT[]>([]);
  const [loadingMatches, setLoadingMatches] = useState<boolean>(true);
  const [seenUserIds, setSeenUserIds] = useState<Set<number>>(new Set());
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [lastReturned, setLastReturned] = useState<number | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = React.useState<
    ReactElement | undefined
  >(undefined);
  const [lastLocationFetch, setLastLocationFetch] = useState<
    number | undefined
  >(undefined);
  // HACK TO GET THE STACK TO RE-RENDER
  const [stackKey, setStackKey] = useState<string>(uuid4());
  const [swipeIcon, setSwipeIcon] = useState(<></>);
  const [swipeAnimationOngoing, setSwipeAnimationOngoing] = useState(false);

  const authContext = getContext();
  const { accountContext, token, setAccount } = authContext;
  const { refreshChats } = getChatContext();

  // Focus listener for the case when a user hasn't gave permission
  // We prompt them permissions, if they deny they might have to manually
  // go to their settings. When they go from background to foreground we could re-prompt
  useEffect(() => {
    // if (!accountContext.profile?.location) {
    const unsubFocusListener = navigation.addListener("focus", () => {
      if (
        lastLocationFetch === undefined ||
        //1 hour
        new Date().getTime() - lastLocationFetch > 3600000
      ) {
        requestLocationPermission();
      }
    });

    const preventBackSwipeListener = navigation.addListener("beforeRemove", (e) => {
      e.preventDefault();
    });

    return () => {
      unsubFocusListener();
      preventBackSwipeListener();
    };
    // }
  }, [navigation]);

  const onForeground = (): void => {
    if (!accountContext.profile?.location && lastLocationFetch === undefined) {
      requestLocationPermission();
    }
  };

  useAppState({ onForeground });

  useEffect(() => {
    if (
      accountContext.profile?.location != undefined &&
      lastReturned === undefined
    ) {
      apiGetNext10Matches(
        accountContext.profile!!.location!!,
        authContext
      ).then((matches) => {
        let retMatches = matches.data as Array<AccountT>;
        setLastReturned(retMatches.length);
        const prefetchPromises = Array<Promise<boolean>>();
        retMatches.forEach((match) => {
          prefetchPromises.push(Image.prefetch(match.profile?.images[0]));
        });
        // We split them in half if we know the API could return more
        if (retMatches.length >= NUM_RETURNED_FROM_API) {
          const halfwayThrough = Math.ceil(retMatches.length / 2);
          setCurrentMatches(retMatches.slice(0, halfwayThrough));
          setNextMatches(retMatches.slice(halfwayThrough, retMatches.length));
        } else {
          setCurrentMatches(retMatches);
        }
        setSeenUserIds(new Set(retMatches.map((match) => match.id!!)));
        setLoadingMatches(false);
        Promise.all(prefetchPromises);
      });
    }
  }, [currentIndex, accountContext, lastLocationFetch]);

  useEffect(() => {
    if (lastReturned != undefined && currentIndex === currentMatches.length) {
      //Switch over next matchs to current matches then refresh next matches
      setCurrentIndex(0);
      setCurrentMatches(nextMatches);
      if (lastReturned >= NUM_RETURNED_FROM_API) {
        addMoreMatches();
      } else {
        setNextMatches([]);
      }
    }
  }, [currentIndex]);

  // HACK TO GET THE STACK TO RE-RENDER https://github.com/lhandel/react-native-card-stack-swiper/issues/43
  useEffect(() => {
    setStackKey(uuid4());
  }, [currentMatches]);

  function onSwipeLeft(index: number) {
    apiDislikeProfile(currentMatches[index].id!!, authContext);
    setCurrentIndex(currentIndex + 1);
    setSwipeIcon(
      <SwipeIcon
        key={index}
        icon={<Icon name="close" color={DISLIKE_ACTIONS} size={350} />}
        animationOngoing={swipeAnimationOngoing}
        setAnimationOngoing={setSwipeAnimationOngoing}
      />
    );
  }

  function onSwipeRight(index: number) {
    //todo toast on new match
    apiLikeProfile(currentMatches[index].id!!, authContext).then((response) => {
      const swipeResp = response.data as SwipeResponseT;
      if (swipeResp.confirmedMatch) {
        const matchedProfile = currentMatches[index];
        Toast.show({
          type: "success",
          text1: "Found a new match!",
          text2:
            "You've matched with " +
            matchedProfile.profile?.name +
            "! Go to messages to start a conversation and schedule a tee time!",
        });
        refreshChats(0);
      }
    });
    setCurrentIndex(currentIndex + 1);
    setSwipeIcon(
      <SwipeIcon
        key={index}
        icon={
          <Icon
            name="golf-select-icon"
            color={LIKE_ACTIONS}
            size={350}
            useCustom
          />
        }
        animationOngoing={swipeAnimationOngoing}
        setAnimationOngoing={setSwipeAnimationOngoing}
      />
    );
  }

  useEffect(() => {
    setSwipeAnimationOngoing(true);
  }, [swipeIcon]);

  function addMoreMatches() {
    apiGetNext10Matches(accountContext.profile!!.location!!, authContext).then(
      (matches) => {
        let retMatches = matches.data as Array<AccountT>;
        setLastReturned(retMatches.length);
        retMatches = retMatches.filter((match) => !seenUserIds.has(match.id!!));
        setNextMatches(retMatches);
        const prefetchPromises = Array<Promise<boolean>>();
        retMatches.forEach((match) => {
          prefetchPromises.push(Image.prefetch(match.profile?.images[0]));
        });
        setSeenUserIds(
          new Set([
            ...Array.from(seenUserIds),
            ...retMatches.map((match) => match.id!!),
          ])
        );
        Promise.all(prefetchPromises);
      }
    );
  }

  const getEndComponent = () => {
    return (
      <View
        style={{
          height: "90%",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={styles.infoMessage}>
          You've ran out of potential matches.
        </Text>
        <Text style={styles.infoMessage}>Check back later!</Text>
      </View>
    );
  };

  const requestLocationPermission = () => {
    if (accountContext != null && token != null && isJwtValid(token)) {
      (async () => {
        try {
          let { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== "granted") {
            Analytics.logEvent("DeniedPermission", {
              permission: "location",
            });
            throw new Error("Not granted");
          }
          let location = await Location.getLastKnownPositionAsync({});
          if (location === null) {
            location = await Location.getCurrentPositionAsync({});
          }
          const profile = accountContext.profile!!;
          profile.location = {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          };
          apiCreateOrUpdateProfile(profile, authContext).then((resp) => {
            accountContext.profile = profile;
            setAccount({ ...accountContext });
            setLastLocationFetch(new Date().getTime());
          });
        } catch (error) {
          console.error(JSON.stringify(error));
          setErrorMessage(
            <GolfView>
              <Text style={{ ...styles.infoMessage, padding: 10 }}>
                You must allow swinger access to location in order to find
                nearby golf-mates.
                {"\n"}
                {"\n"}
                Please go to your settings and enable Swinger to have location
                permissions.
              </Text>
              <SwingerButton onPress={() => Linking.openSettings()}>
                Go to Settings
              </SwingerButton>
            </GolfView>
          );
        }
      })();
    }
  };

  function getMainComponent() {
    if (errorMessage) {
      return errorMessage;
    }
    if (
      !loadingMatches &&
      currentIndex >= currentMatches.length &&
      nextMatches.length === 0
    ) {
      return getEndComponent();
    } else if (loadingMatches) {
      return null;
    } else {
      return (
        <View
          style={{
            flex: 1,
          }}
        >
          {/* @ts-ignore */}
          <CardStack
            style={{
              flex: 1,
              alignItems: "center",
            }}
            key={stackKey}
            verticalSwipe={false}
            renderNoMoreCards={() => {
              return <></>;
            }}
            ref={swiper}
            onSwipedLeft={(index) => onSwipeLeft(index)}
            onSwipedRight={(index) => onSwipeRight(index)}
          >
            {swiper ? (
              currentMatches.map((item) => (
                // @ts-ignore
                <Card key={item.id}>
                  <CardItem data={item} swiper={swiper} />
                </Card>
              ))
            ) : (
              <></>
            )}
          </CardStack>
        </View>
      );
    }
  }

  return (
    <>
      {swipeAnimationOngoing && (
        <View
          pointerEvents="none"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
          }}
        >
          {swipeIcon}
        </View>
      )}
      <GolfView
        disableKeyboardOverride={true}
        containerOverride={styles.containerHome}
      >
        <>{getMainComponent() != null ? getMainComponent() : <></>}</>
      </GolfView>
      <LoadingSpinner isLoading={getMainComponent() == null} />
    </>
  );
};

export default Home;
