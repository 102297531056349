import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import axiosBetterStacktrace from "axios-better-stacktrace";
import * as Application from "expo-application";
import Constants from "expo-constants";
import * as Analytics from "expo-firebase-analytics";
import Toast from "react-native-toast-message";
import {
  AuthContextT,
  GolfMatchT,
  ImageUpdateT,
  Location,
  ProfileItemT,
} from "../types";

export const rootUrl = Constants!!.manifest!!.extra!!.APIURL;

export async function getAxios(
  authContext?: AuthContextT,
  token?: string
): Promise<AxiosInstance> {
  var token = authContext?.token ?? token;
  token = await maybeRefreshToken(authContext, token);
  const instance = axios.create({ headers: getHeaders(token) });
  axiosBetterStacktrace(instance);
  instance.interceptors.request.use(function (request) {
    try {
      if (request.headers != null) {
        request.headers["requestStartTime"] = new Date().getTime();
      } else {
        request.headers = {
          requestStartTime: new Date().getTime(),
        };
      }
    } catch (e) {}
    return request;
  });
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      responseTime(response);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      try {
        console.error(JSON.stringify(error.response.data));
        responseTime(error.response);
        error(error.response.data, authContext?.accountContext?.id);
        if (error.response.status >= 500) {
          Toast.show({
            type: "error",
            text1: "Something went wrong",
            text2: "Please try again",
          });
        }
        if (error.response.status === 401) {
          handleUnauthorized(error, authContext);
        }
      } catch (e) {}
      return Promise.reject(error);
    }
  );
  return instance;
}

async function maybeRefreshToken(
  authContext?: AuthContextT,
  token?: string
): Promise<string> {
  let newToken = token;
  if (
    authContext != null &&
    new Date(authContext.expirationTime) < new Date()
  ) {
    try {
      if (authContext.refreshToken != null) {
        newToken = await authContext.doRefreshToken(authContext.refreshToken);
      } else {
        //There's no way to recover an auth token besides re-initiating sign in
        throw new Error("user unauthorized - signing out");
      }
    } catch (error) {
      console.error(JSON.stringify(error));
      authContext.signOut();
      throw error;
    }
  }
  return newToken!!;
}

async function responseTime(response: AxiosResponse) {
  try {
    const startTimeMs = response.config.headers!!["requestStartTime"] as number;
    const totalTime = new Date().getTime() - startTimeMs;
    Analytics.logEvent("apiResponseTimeMs", {
      url: response.config.baseURL!!,
      method: response.config.method,
      timeTakenMs: totalTime,
    });
  } catch (e) {
    console.error("issue parinsg response time ms", e);
  }
}

async function error(error: any, accountId?: string) {
  try {
    Analytics.logEvent("apiError", {
      errorResponse: error.response.data,
      url: error.response.config.baseURL,
      errorStatus: error.response.status,
    });
  } catch (e) {}
}

async function handleUnauthorized(
  error: any,
  authContext?: AuthContextT
): Promise<AxiosInstance> {
  if (authContext?.refreshToken != null) {
    await authContext!!.doRefreshToken(authContext!!.refreshToken);
  } else {
    //There's no way to recover an auth token besides re-initiating sign in
    authContext?.signOut();
  }
  return Promise.reject(error);
}

function getHeaders(token?: string): AxiosRequestHeaders {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

export const apiSignIn = function (
  email: string,
  password: string
): AxiosPromise<any> {
  return getAxios().then((axios) => {
    return axios.post(`${rootUrl}/login`, {
      username: email,
      password: password,
    });
  });
};

export const apiIntegrationSignIn = function (
  integration: string,
  token?: string,
  idToken?: string,
  fullName?: string
): AxiosPromise<any> {
  return getAxios().then((axios) => {
    return axios.post(`${rootUrl}/integration/login`, {
      accessToken: token,
      integrationType: integration,
      idToken: idToken,
      fullName: fullName,
    });
  });
};

export const apiRefreshToken = function (
  refreshToken: string
): AxiosPromise<any> {
  return getAxios().then((axios) => {
    return axios.post(`${rootUrl}/oauth/access_token`, {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });
  });
};

export const apiSignUp = function (
  email: string,
  password: string
): AxiosPromise<any> {
  return getAxios().then((axios) => {
    return axios.post(`${rootUrl}/account`, {
      email: email,
      password: password,
    });
  });
};

export const apiGetAccount = function (token: string): AxiosPromise<any> {
  return getAxios(undefined, token).then((axios) => {
    return axios.get(`${rootUrl}/account`);
  });
};

export const apiCreateOrUpdateProfile = function (
  profile: ProfileItemT,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(`${rootUrl}/profile`, profile);
  });
};

export const apiUploadImages = function (
  imageUpdate: ImageUpdateT,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(`${rootUrl}/profile/images`, imageUpdate);
  });
};

export const apiGetChats = function (
  offset: number,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/chat/${offset}`);
  });
};

export const apiGetMoreMessages = function (
  chatId: number,
  offset: number,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/chat/${chatId}/${offset}`);
  });
};

export const apiGetGolfMatches = function (authContext: AuthContextT) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/golf/match/available`);
  });
};

export const apiGetMyGolfMatches = function (authContext: AuthContextT) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/golf/match`);
  });
};

export const apiGetProfiles = function (
  accountIds: Array<number>,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(`${rootUrl}/account/search`, {
      accountIds: accountIds,
    });
  });
};

export const apiCreateMatch = function (
  golfMatch: GolfMatchT,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(`${rootUrl}/golf/match`, golfMatch);
  });
};

export const apiUpdateMatch = function (
  golfMatch: GolfMatchT,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.put(`${rootUrl}/golf/match`, golfMatch);
  });
};

export const apiAttendingGolfMatch = function (
  golfMatch: GolfMatchT,
  attending: boolean,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(
      `${rootUrl}/golf/match/attending/${attending}`,
      golfMatch
    );
  });
};

export const apiGetAllMatchedProfiles = function (authContext: AuthContextT) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/matches/`);
  });
};

export const apiGetNext10Matches = function (
  location: Location,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.post(`${rootUrl}/matches/next`, location);
  });
};

export const apiGetAllBlockedAccounts = function (authContext: AuthContextT) {
  return getAxios(authContext).then((axios) => {
    return axios.get(`${rootUrl}/matches/blocked`);
  });
};

export const apiLikeProfile = function (
  profileId: number,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.put(`${rootUrl}/matches/like/${profileId}`);
  });
};

export const apiFeatureToggles = function (authContext: AuthContextT) {
  var axios;
  if (authContext.token != null) {
    axios = getAxios(authContext);
  } else {
    axios = getAxios();
  }
  return axios.then((axios) => {
    return axios.post(`${rootUrl}/features`, {
      version: Application.nativeBuildVersion,
    });
  });
};

export const apiDislikeProfile = function (
  profileId: number,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.put(`${rootUrl}/matches/dislike/${profileId}`);
  });
};

export const apiBlockAccount = function (
  accountId: number,
  block: boolean,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.put(`${rootUrl}/matches/block/${accountId}/${block}`);
  });
};

export const apiReportAccount = function (
  accountId: number,
  authContext: AuthContextT
) {
  return getAxios(authContext).then((axios) => {
    return axios.put(`${rootUrl}/account/report/${accountId}`);
  });
};

export const apiDeleteAccount = function (authContext: AuthContextT) {
  return getAxios(authContext).then((axios) => {
    return axios.delete(`${rootUrl}/account`);
  });
};
