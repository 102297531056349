import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Text, View } from "react-native";
import ModalSelector from "react-native-modal-selector";
import styles from "../assets/styles";
import { apiCreateOrUpdateProfile } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { handleError } from "../utils/ErrorUtil";
import GolfView from "./GolfView";
import LoadingComponent from "./LoadingComponent";
import MastersHeader from "./MastersHeader";
import SwingerButton from "./SwingerButton";
import SwingerTextInput from "./SwingerTextInput";

type Props = {
  onUpdateComplete: Function;
  showLegal: boolean;
  headerText: string;
  buttonText: string;
};

const initDate = new Date();
const forteenYearsOld = new Date();
forteenYearsOld.setFullYear(initDate.getFullYear() - 14);

const ProfileEdit = ({
  onUpdateComplete,
  showLegal,
  headerText,
  buttonText,
}: Props) => {
  const [drinkPref, setDrinkPref] = useState<string>("");
  const [cartPref, setCartPref] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [gender, setGender] = useState<string | undefined>(undefined);
  const [handicap, setHandicap] = useState<string | undefined>(undefined);
  const [errorText, setErrorText] = React.useState<string>("");
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [successfulSave, setSuccessfulSave] = useState<boolean>(false);
  const refHandicap = useRef<any>();
  const refName = useRef<any>();

  const authContext = getContext();
  const { accountContext, setAccount } = authContext;

  //TODO LOCATION
  useEffect(() => {
    if (accountContext != null && accountContext.profile != null) {
      const profile = accountContext.profile;
      setName(profile.name);
      setDrinkPref(profile.alcohol ?? "");
      setCartPref(profile.ride ?? "");
      setDob(
        profile.dob
          ? new Date(accountContext!!.profile!!.dob!!).toLocaleDateString(
              undefined,
              {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }
            )
          : ""
      );
      setGender(profile.gender ?? undefined);
      setHandicap(
        profile.handicap != undefined ? profile.handicap.toString() : undefined
      );
    }
  }, [accountContext]);

  // This is a hack because the entire state re-renders when accountContext is updated
  // and navigation doesn't work
  useEffect(() => {
    if (successfulSave) {
      onUpdateComplete();
    }
  }, [successfulSave]);

  const onContinue = () => {
    let dobDate = null;
    let selectedGender = gender;
    if (dob == null || dob == "") {
      setErrorText("Date of Birth must be filled in.");
      return;
    }
    try {
      dobDate = new Date(dob);
    } catch (error) {}
    if (dobDate == null) {
      setErrorText("Date of Birth must be filled in.");
      return;
    }
    if (name === undefined) {
      setErrorText("Please enter your name.");
      return;
    }
    if (dobDate > forteenYearsOld) {
      setErrorText("Must be 14 years old.");
      return;
    }
    if (handicap == null || handicap == "") {
      setErrorText("Please enter your estimated handicap.");
      return;
    }
    if (gender === undefined) {
      setGender("Prefer not to say");
      selectedGender = "Prefer not to say";
    }
    if (drinkPref === "") {
      setErrorText("Please enter your alcohol preference.");
      return;
    }
    if (cartPref === "") {
      setErrorText("Please enter your cart preference.");
      return;
    }
    const existingProfile = accountContext.profile ?? {};
    const updatedProfile = {
      ...existingProfile,
      name,
      dob: dobDate.getTime(),
      handicap: parseInt(handicap),
      ride: cartPref,
      alcohol: drinkPref,
      gender: selectedGender,
      isNux: accountContext.profile ? false : true,
    };
    setErrorText("");
    setLoading(true);
    apiCreateOrUpdateProfile(updatedProfile, authContext)
      .then((response) => {
        const profile = response.data as any;
        accountContext.profile = profile;
        setAccount({ ...accountContext });
        setSuccessfulSave(true);
      })
      .catch((error: AxiosError) => {
        console.error(JSON.stringify(error.response));
        setErrorText(handleError(error));
      })
      .finally(() => setLoading(false));
  };

  const blurAll = () => {
    try {
      refHandicap.current.blur();
      refName.current.blur();
    } catch (e) {}
  };

  let drinkPrefIndex = 0;
  const drinkPrefData = [
    { key: drinkPrefIndex++, section: true, label: "🍻 or 🥃 on the course?" },
    { key: drinkPrefIndex++, label: "Don't care either way", value: "NO_PREF" },
    { key: drinkPrefIndex++, label: "Never", value: "NEVER" },
    { key: drinkPrefIndex++, label: "Sometimes", value: "SOMETIMES" },
    { key: drinkPrefIndex++, label: "Always", value: "ALWAYS" },
  ];

  let idx = 0;
  const cartPrefData = [
    {
      key: idx++,
      section: true,
      label: "🛺 or 🚶‍♂️ during your ride?",
    },
    { key: idx++, label: "Don't care either way", value: "NO_PREF" },
    { key: idx++, label: "Always Walk", value: "ALWAYS_WALK" },
    { key: idx++, label: "Like to Walk", value: "LIKE_TO_WALK" },
    { key: idx++, label: "Either", value: "EITHER" },
    { key: idx++, label: "Like to Ride", value: "LIKE_TO_RIDE" },
    { key: idx++, label: "Always Ride", value: "ALWAYS_RIDE" },
  ];

  const genderData = [
    {
      key: idx++,
      section: true,
      label: "What is your gender?",
    },
    { key: idx++, label: "Male", value: "MALE" },
    { key: idx++, label: "Female", value: "FEMALE" },
    { key: idx++, label: "Prefer not to say", value: "OPT_OUT" },
  ];

  return (
    <LoadingComponent isLoading={loading}>
      <GolfView enableScrollView={true}>
        <MastersHeader text={headerText} />
        <Text style={styles.errorText}>{errorText}</Text>
        <SwingerTextInput
          label="What is your name?"
          onChangeText={(text: string) => {
            setName(text);
          }}
          value={name}
          style={{ ...styles.textInput, marginTop: 10 }}
          ref={refName}
        />
        <SwingerTextInput
          label="Date of Birth"
          keyboardType="numeric"
          onChangeText={(text: any) => {
            setDob(text);
          }}
          placeholder="MM/DD/YYYY"
          value={dob}
          style={{ ...styles.textInput, marginTop: 10 }}
          textMaskType={"datetime"}
          textMaskOption={{
            format: "MM/DD/YYYY",
          }}
        />
        <SwingerTextInput
          label="What is your estimated handicap?"
          keyboardType="numeric"
          onChangeText={(text: any) => {
            if (!isNaN(text as any)) {
              setHandicap(text);
            }
          }}
          value={handicap}
          maxLength={2}
          ref={refHandicap}
          style={{ ...styles.textInput, marginTop: 10 }}
        />
        <ModalSelector
          data={genderData}
          onModalOpen={() => {
            blurAll();
          }}
          initValue="What is your gender?"
          onChange={(option) => {
            if (option.value != undefined) {
              setGender(option.label);
            }
          }}
        >
          <SwingerTextInput
            label="What is your gender?"
            value={gender}
            onChangeText={() => {}}
            style={{ ...styles.textInput, marginTop: 10 }}
            placeholder="What is your gender?"
          />
        </ModalSelector>
        <ModalSelector
          data={drinkPrefData}
          initValue="🍻 or 🥃 on the course?"
          onChange={(option) => {
            if (option.value != undefined) {
              setDrinkPref(option.label);
            }
          }}
          onModalOpen={() => {
            blurAll();
          }}
        >
          <SwingerTextInput
            label="🍻 or 🥃 on the course?"
            value={drinkPref}
            onChangeText={() => {}}
            style={{ ...styles.textInput, marginTop: 10 }}
            placeholder="🍻 or 🥃 on the course?"
          />
        </ModalSelector>
        <ModalSelector
          data={cartPrefData}
          initValue="🛺 or 🚶‍♂️ during your ride?"
          onChange={(option) => {
            if (option.value != undefined) {
              setCartPref(option.label);
            }
          }}
          onModalOpen={() => {
            blurAll();
          }}
        >
          <SwingerTextInput
            label="🛺 or 🚶‍♂️ during your ride?"
            value={cartPref}
            onChangeText={() => {}}
            style={{ ...styles.textInput, marginTop: 10 }}
            placeholder="🛺 or 🚶‍♂️ during your ride?"
          />
        </ModalSelector>
        <SwingerButton
          // style={{ marginTop: 20 }}
          onPress={() => onContinue()}
        >
          {buttonText}
        </SwingerButton>
        <View style={{ height: 100 }}></View>
      </GolfView>
    </LoadingComponent>
  );
};

export default ProfileEdit;
