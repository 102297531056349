import axios from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { getAxios } from "../services/ApiService";
import { GooglePlacesLocation, GooglePlacesResponse } from "../types";
import { uuid4 } from "@sentry/utils";

const RADIUS = 5000;
const SESSION_ID = uuid4();

export const useGooglePlacesAutoComplete = (
  input: string,
  locationStr: string,
  apiKey: string,
  options?: UseQueryOptions<GooglePlacesLocation[]>
) => {
  async function getGooglePlaces() {
    if (input == "") {
      return [];
    }
    const resp = await getAxios().then(() =>
      axios.get(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&location=${locationStr}&radius=${RADIUS}&strictBounds=false&types=establishment&key=${apiKey}&sessiontoken=${SESSION_ID}`
      )
    );
    const googData = await resp.data as GooglePlacesResponse;
    return googData.predictions;

  }
  return useQuery<GooglePlacesLocation[]>([input], getGooglePlaces, options);
};
