import { registerRootComponent } from 'expo';
import * as Sentry from 'sentry-expo';
import { CaptureConsole } from "@sentry/integrations";

import PlatformHandlerRoot from './PlatformHandlerRoot';

Sentry.init({
    dsn: "https://ff66f43b6c074928a8f835158f033e30@o1198571.ingest.sentry.io/6321113",
    // enableInExpoDevelopment: true,
    integrations: [
      new CaptureConsole({
        levels: ['error']
      })
    ],
  });

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(PlatformHandlerRoot);
