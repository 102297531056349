import React, { ReactElement } from "react";
import { Text, View } from "react-native";

interface Props {}

export default function LogoBig({}: Props): ReactElement {
  return (
    <View>
      <Text
        style={{
          fontSize: 80,
          fontFamily: "LibreBaskerville_400Regular_Italic",
          color: "#076652",
          letterSpacing: 1,
          textAlign: "center",
          margin: 20,
          marginTop: 90,
        }}
      >
        Swinger
      </Text>
    </View>
  );
}
