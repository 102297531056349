import { StackScreenProps } from "@react-navigation/stack";
import React, { useCallback, useEffect, useState } from "react";
import {
  DeviceEventEmitter,
  TouchableOpacity,
  View,
} from "react-native";
import { GiftedChat, IMessage } from "react-native-gifted-chat";
import { Icon } from "../components";
import { apiGetMoreMessages } from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { getChatContext } from "../services/ChatContext";
import { SENT } from "../services/WebsocketContext";
import { AccountT, ChatMessageT, RootStackParamList } from "../types";
import {
  getChatTitle,
  iMessageToMessage,
  messageToIMessage,
} from "../utils/ChatUtils";
import { getLinkColor } from "../utils/StyleUtils";

type Props = StackScreenProps<RootStackParamList, "Chat">;
// @ts-ignore
function isCloseToTop({ layoutMeasurement, contentOffset, contentSize }) {
  const paddingToTop = 80;
  return (
    contentSize.height - layoutMeasurement.height - paddingToTop <=
    contentOffset.y
  );
}

export default function Chat({ route, navigation }: Props) {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const { chatId } = route.params;

  const authContext = getContext();
  const { accountContext } = authContext;
  const { threadMap, updateThread, getAccountsOrLoad, getAccounts } =
    getChatContext();

  useEffect(() => {
    getAccountsOrLoad(threadMap.get(chatId)!!.allAccountIds).then(
      (retAccounts: Array<AccountT>) => {
        navigation.setOptions({
          headerRight: (props) => (
            <View style={{ marginRight: 15, justifyContent: "center" }}>
              <TouchableOpacity onPress={() => handleInfoTouch()}>
                <Icon
                  color={getLinkColor()}
                  name={"information-circle-outline"}
                  size={22}
                />
              </TouchableOpacity>
            </View>
          ),
          title: getChatTitle(
            threadMap.get(chatId)!!,
            accountContext,
            retAccounts
          ),
          headerBackTitleVisible: false,
        });
      }
    );

    const unsubscribe = navigation.addListener("focus", () => {
      clearRead();
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    getAccountsOrLoad(threadMap.get(chatId)!!.allAccountIds).then(
      (accounts: Array<AccountT>) => {
        const iMessages = messageToIMessage(
          accountContext,
          accounts,
          threadMap.get(chatId)?.messages ?? []
        );
        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, iMessages)
        );
        clearRead();
      }
    );
  }, [threadMap]);

  const clearRead = () => {
    if (threadMap.get(chatId)?.unread) {
      const chat = threadMap.get(chatId)!!;
      chat.unread = false;
      updateThread(chatId, chat);
      DeviceEventEmitter.emit(SENT, JSON.stringify({ chatRead: chat.id }));
    }
  };

  const loadMore = () => {
    if (!loading) {
      setLoading(true);
      apiGetMoreMessages(
        threadMap.get(chatId)?.id!!,
        currentOffset + 40,
        authContext
      )
        .then((resp) => {
          const messages = resp.data as Array<ChatMessageT>;
          const iMessages = messageToIMessage(
            accountContext,
            getAccounts(threadMap.get(chatId)!!),
            messages
          );
          setMessages((previousMessages) =>
            GiftedChat.append(previousMessages, iMessages)
          );
          setCurrentOffset(currentOffset + 40);
          setLoading(false);
        })
        .catch((resp) => {
          setLoading(false);
        });
    }
  };

  const onSend = useCallback((messages: Array<IMessage>) => {
    messages.forEach((message) => {
      const chatMessage = iMessageToMessage(accountContext, chatId, message);
      DeviceEventEmitter.emit(SENT, JSON.stringify({ chatMessage }));
    });
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  const handleInfoTouch = () => {
    const accountIds = threadMap
      .get(chatId)!!
      .accountIds.filter((id) => id !== accountContext.id!!);
    if (accountIds.length === 1) {
      navigation.navigate("User Info", {
        accountId: accountIds[0],
        isBlocked: false,
      });
    } else if (accountIds.length > 1) {
      navigation.navigate("User Select", {
        accountIds: accountIds,
        title: "Chat Members",
      });
    }
  };

  return (
    <GiftedChat
      messages={messages}
      onSend={(messages) => onSend(messages)}
      user={{
        _id: accountContext.id!,
      }}
      listViewProps={{
        scrollEventThrottle: 400,
        // @ts-ignore
        onScroll: ({ nativeEvent }) => {
          if (isCloseToTop(nativeEvent)) {
            loadMore();
          }
        },
      }}
    />
  );
}
