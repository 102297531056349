import * as Updates from "expo-updates";
import { useEffect } from "react";
import { Platform } from "react-native";

export default function useSwingerUpdates() {
  useEffect(() => {
    checkForUpdates();
    const timer = setInterval(() => {
    }, /*5 mins*/300000);
    return () => clearInterval(timer);
  }, []);

  const checkForUpdates = async () => {
    if (__DEV__ || Platform.OS === 'web') {
      return;
    }
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    } catch (e) {
      console.error("Issue with update", e);
    }
  };

  return [];
}
