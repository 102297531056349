import { useEffect, useState } from "react";
import { AppState, AppStateStatus } from "react-native";

type Props = {
  onBackground?: Function;
  onForeground?: Function;
};

export default function useAppState({
  onBackground,
  onForeground,
}: Props) {
  const [currentAppState, setCurrentAppState] = useState<string>(
    AppState.currentState
  );

  useEffect(() => {
    AppState.addEventListener("change", callback);

    return () => {
      AppState.removeEventListener("change", callback);
    };
  }, []);

  const callback = (nextAppState: AppStateStatus): void => {
    console.log(nextAppState);
    if (
      onForeground &&
      currentAppState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      onForeground();
    } else if (
      onBackground &&
      nextAppState.match(/inactive|background/)
    ) {
      onBackground();
    }
    setCurrentAppState(nextAppState);
  };

  return [];
}
