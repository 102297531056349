import * as Analytics from "expo-firebase-analytics";
import React from "react";
import { DeviceEventEmitter } from "react-native";
import { getContext } from "../services/AuthContext";
import { cContext as ChatContext } from "../services/ChatContext";
import { AccountT, ChatMessageT, ChatT } from "../types";
import { apiGetChats, apiGetProfiles } from "./ApiService";
import { RECEIVED } from "./WebsocketContext";
import * as Sentry from "sentry-expo";

type Props = {
  children: React.ReactNode;
  setPendingNotifications: Function;
  setErrorMessage: Function;
};
/**
 * Handles state changes once a user is logged in
 */
const LoggedInContext = ({
  children,
  setPendingNotifications,
  setErrorMessage,
}: Props) => {
  const authContext = getContext();
  const { accountContext, token } = authContext;

  const [chatState, dispatchChatState] = React.useReducer(
    (
      prevState: any,
      action: {
        type: any;
        id?: number;
        chat?: ChatT;
        threadMap?: Map<number, ChatT>;
        accountsMap?: Map<number, AccountT>;
      }
    ) => {
      switch (action.type) {
        case "UPDATE_THREAD":
          const clonedThreadMap = new Map(prevState.threadMap);
          clonedThreadMap.set(action.id, { ...action.chat });
          return {
            ...prevState,
            threadMap: clonedThreadMap,
          };
        case "SET_THREADS":
          return {
            ...prevState,
            threadMap: action.threadMap,
          };
        case "SET_ACCOUNTS_MAP":
          return {
            ...prevState,
            accountsMap: new Map(action.accountsMap),
          };
      }
    },
    {
      threadMap: new Map(),
      accountsMap: new Map(),
    }
  );

  const chatContext = React.useMemo(
    () => ({
      updateThread: async (id: number, chat: ChatT) => {
        dispatchChatState({ type: "UPDATE_THREAD", id, chat });
      },
      setThreads: async (threadMap: Map<number, ChatT>) => {
        dispatchChatState({ type: "SET_THREADS", threadMap });
      },
      setAccountsMap: async (accountsMap: Map<number, AccountT>) => {
        dispatchChatState({ type: "SET_ACCOUNTS_MAP", accountsMap });
      },
      addAccountsToAccountsMap: async (accounts: Array<AccountT>) => {
        accounts.forEach((account) => {
          chatState.accountsMap.push(account.id!!, account);
        });
        chatContext.setAccountsMap(new Map(chatState.accountsMap));
      },
      refreshChats: async (offset: number) => {
        return reloadChats(offset);
      },
      getAccounts: (accountIds: Array<number>) => {
        return accountIds.map((accountId) => {
          return chatState.accountsMap.get(accountId);
        }).filter((account) => account != null);
      },
      getAccountsOrLoad: async (accountIds: Array<number>) => {
        return await getAccountsOrFetch(accountIds);
      },
      threadMap: chatState.threadMap,
    }),
    [chatState]
  );

  React.useEffect(() => {
    if (token != null) {
      const messageListener = DeviceEventEmitter.addListener(
        RECEIVED,
        (data) => {
          const chatMessage = data as ChatMessageT;
          let thread: ChatT | null = null;
          if (chatContext.threadMap.has(chatMessage.fromAccountId)) {
            thread = chatContext.threadMap.get(chatMessage.fromAccountId)!!;
            thread!!.messages = [...thread!!.messages, chatMessage];
            thread!!.lastMessageTs = new Date().toString();
            thread!!.unread = true;
            chatContext.updateThread(chatMessage.chatId, { ...thread!! });
          } else {
            chatContext.refreshChats(0);
          }
        }
      );
      chatContext.refreshChats(0);
      return () => messageListener.remove();
    }
  }, [token]);

  function initThreadsAndAccounts(threads: Array<ChatT>): Promise<any> {
    const otherAccounts = threads
      .map((thread) => {
        let accountIdSet = new Set(
          thread.allAccountIds.filter(
            (accountId) => accountId != accountContext.id
          )
        );
        accountIdSet = new Set([
          ...accountIdSet,
          ...thread.accountIds.filter(
            (accountId) => accountId != accountContext.id
          ),
        ]);
        return Array.from(accountIdSet);
      })
      .flat();

    const profileMap = new Map(chatState.accountsMap) as Map<number, AccountT>;

    const initThreadMap = new Map(chatContext.threadMap) as Map<number, ChatT>;
    return apiGetProfiles(otherAccounts, authContext).then((resp) => {
      resp.data.forEach((account: AccountT) => {
        profileMap.set(account.id!, account);
      });
      chatContext.setAccountsMap(profileMap);

      threads.forEach((thread) => {
        initThreadMap.set(thread.id, thread);
      });
      chatContext.setThreads(initThreadMap);
    });
  }

  function reloadChats(offset: number): Promise<any> {
    return apiGetChats(offset, authContext).then((resp) => {
      if (resp.data && resp.data.length > 0) {
        return initThreadsAndAccounts(resp.data);
      }
    });
  }

  async function getAccountsOrFetch(
    accountIds: Array<number>
  ): Promise<Array<AccountT>> {
    const accountsToFetch: Array<number> = [];
    let foundAccounts = accountIds
      .map((accountId) => {
        if (chatState.accountsMap.has(accountId)) {
          return chatState.accountsMap.get(accountId);
        } else {
          accountsToFetch.push(accountId);
          return null;
        }
      })
      .filter((account) => account != null);

    if (accountsToFetch.length > 0) {
      const resp = await apiGetProfiles(accountsToFetch, authContext);
      const fetchedAccounts = resp.data as Array<AccountT>;

      fetchedAccounts.forEach((account) => {
        chatState.accountsMap.set(account.id!!, account);

        chatContext.setAccountsMap(new Map(chatState.accountsMap));
        foundAccounts = [...foundAccounts, ...fetchedAccounts];
      });
    }

    return foundAccounts.filter((account) => account != null);
  }

  React.useEffect(() => {
    if (chatContext.threadMap != null) {
      const unreadThreads = [...chatContext.threadMap].filter(([k, v]) => {
        return v.unread;
      });
      const numUnread = unreadThreads.length;

      if (numUnread > 0) {
        setPendingNotifications(numUnread);
      } else {
        setPendingNotifications(undefined);
      }
    }
  }, [chatContext.threadMap]);

  React.useEffect(() => {
    if (accountContext != null && accountContext.id) {
      Analytics.setUserId(accountContext.id.toString());
      Sentry.Browser.setUser({ email: accountContext.email });
    }
  }, [token]);

  return (
    <ChatContext.Provider value={chatContext}>{children}</ChatContext.Provider>
  );
};

export default LoggedInContext;
