import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { DeviceEventEmitter } from "react-native";
import useAppState from "../hooks/useAppState";
import { getContext } from "../services/AuthContext";
import { WsMessageT } from "../types";
import { isJwtValid } from "../utils/JwtUtil";

type Props = { children: React.ReactNode };

export const SENT = "local-message-sent";
export const READ = "local-message-read";
export const RECEIVED = "local-message-received";

const rootWsUrl = Constants!!.manifest!!.extra!!.WSURL;

const WebsocketContext = ({ children }: Props) => {
  const [conn, setConn] = useState<WebSocket | null>(null);
  const [tokenUsed, setTokenUsed] = useState<string>("");
  const [messageQueue, setMessageQueue] = useState<string[]>([]);

  const authContext = getContext();
  const { accountContext, token } = authContext;

  useEffect(() => {
    const listener = DeviceEventEmitter.addListener(SENT, (data) => {
      setMessageQueue([...messageQueue, data]);
    });
    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    handleConnectWS();
    return () => {
      if (conn != null) {
        conn.close();
      }
    };
  }, [authContext]);

  const handleConnectWS = () => {
    if (
      token != null &&
      isJwtValid(token) &&
      conn === null &&
      token != tokenUsed
    ) {
      setTokenUsed(token);
      connectWS(token);
    }
  }

  const connectWS = (tokenValue: string) => {
    if (token === tokenValue && conn === null) {
      try {
        const conn = new WebSocket(
          `${rootWsUrl}/ws/notifs/${accountContext.id}?access_token=${token}`,
          []
        );

        conn.onopen = () => {
          setConn(conn);
          console.log("CONNECTED");
          if (messageQueue.length > 0) {
            sendMessage(conn);
          }
        };

        conn.onclose = () => {
          console.log("DISCONNECTED");
          setConn(null);
          setTimeout(() => {
            connectWS(tokenValue);
          }, 10000);
        };

        conn.onerror = (error) => {
          console.error(JSON.stringify(error));
        };

        conn.onmessage = (evt) => {
          // listen to data sent from the websocket server
          console.log("RECEIVED");
          const message = JSON.parse(evt.data);
          DeviceEventEmitter.emit(RECEIVED, message);
        };
      } catch (error) {
        console.error(JSON.stringify(error));
        setTimeout(() => {
          connectWS(tokenValue);
        }, 10000);
      }
    }
  };

  useEffect(() => {
    if (conn != null) {
      sendMessage(conn);
    }
  }, [messageQueue]);

  const sendMessage = (conn: WebSocket) => {
    const arr = [...messageQueue];
    arr.reverse();
    arr.forEach((message) => {
      const wsMesage = JSON.parse(message) as WsMessageT;
      if (wsMesage.chatMessage != null) {
        wsMesage.chatMessage.createdTime = undefined;
      }
      conn.send(JSON.stringify(wsMesage));
    });
  };

  const onForeground = () => {
    handleConnectWS();
  }

  const onBackground = () => {
    if (conn != null) {
      conn.close();
    }
  }

  useAppState({onBackground, onForeground});

  return <>{children}</>;
};

export default WebsocketContext;
