import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import { RootStackParamList } from "../../types";
import * as WebBrowser from "expo-web-browser";
import { getEULALink, getPrivacyPolicyLink } from "../../utils/LegalUtils";

type Props = StackScreenProps<RootStackParamList, "Legal">;

const Legal = ({ route, navigation }: Props) => {
  return (
    <>
      {getEULALink({marginTop: 20, fontSize: 20})}
      {getPrivacyPolicyLink({marginTop: 20, fontSize: 20})}
    </>
  );
};

export default Legal;
