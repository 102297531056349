import { StackScreenProps } from "@react-navigation/stack";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Text } from "react-native";
import styles from "../../assets/styles";
import GolfView from "../../components/GolfView";
import LoadingComponent from "../../components/LoadingComponent";
import MastersHeader from "../../components/MastersHeader";
import SwingerButton from "../../components/SwingerButton";
import SwingerTextInput from "../../components/SwingerTextInput";
import { apiSignUp } from "../../services/ApiService";
import { getContext } from "../../services/AuthContext";
import { RootStackParamList } from "../../types";
import { handleError } from "../../utils/ErrorUtil";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/ProfileUtils";

type Props = StackScreenProps<RootStackParamList>;

function CreateAccount({ navigation }: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorText, setErrorText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const { signIn, accountContext } = getContext();

  useEffect(() => {
    if (accountContext != null) {
      navigation.navigate("Create Your Profile");
    }
  }, [accountContext]);

  const navigateCreateProfile = () => {
    if (!EMAIL_REGEX.test(email)) {
      setErrorText("Must be a valid email.");
      return;
    }
    if (!PASSWORD_REGEX.test(password)) {
      setErrorText(
        "Your password must be at least 8 characters and contain at least 1 digit and 1 uppercase letter."
      );
      return;
    }
    if (password != passwordConfirm) {
      setErrorText("Passwords do not match.");
      return;
    }
    setErrorText("");
    setIsLoading(true);
    apiSignUp(email, password)
      .then((response) => {
        signIn(email, password);
        navigation.navigate("Create Your Profile");
      })
      .catch((error: AxiosError) => {
        setErrorText(handleError(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <LoadingComponent isLoading={isLoading}>
      <GolfView keyboardVerticalOffset={80} enableScrollView={true}>
        <MastersHeader
          text="Welcome to Swinger"
          subtext="Let's create your account!"
        />
        <Text style={styles.errorText}>{errorText}</Text>
        <SwingerTextInput label="Email" value={email} onChangeText={setEmail} />
        <SwingerTextInput
          label="Password"
          value={password}
          onChangeText={setPassword}
          placeholder={"8 Characters with 1 Number & 1 Uppercase"}
          secureTextEntry
        />
        <SwingerTextInput
          label="Confirm your Password"
          value={passwordConfirm}
          onChangeText={setPasswordConfirm}
          secureTextEntry
          onSubmitEditing={navigateCreateProfile}
        />
        <SwingerButton onPress={() => navigateCreateProfile()}>
          Continue
        </SwingerButton>
      </GolfView>
    </LoadingComponent>
  );
}

export default CreateAccount;
