import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect } from "react";
import ImageEdit from "../components/ImageEdit";
import { RootStackParamList } from "../types";

type Props = StackScreenProps<RootStackParamList>;

const UpdatePhotos = ({ navigation }: Props) => {
  useEffect(() => {
    navigation.setOptions({
      headerBackTitleVisible: false,
    });
  }, []);
  return (
    <>
      <ImageEdit
        navigator={navigation}
        headerText={"Update Profile Photos"}
        buttonText={"Save"}
        onUpdateComplete={() => navigation.goBack()}
        noImagesErrorMessage={"Must have at least 1 photo!"}
      />
    </>
  );
};

export default UpdatePhotos;
