import jwt_decode from "jwt-decode";


export function isJwtValid(token? : any) {
    if(token === undefined || token === null) {
        return false;
    }
   const decodedToken = jwt_decode(token) as any;
   const expiry = decodedToken.exp;
   const now = new Date();
   return !(now.getTime() > expiry * 1000);
}