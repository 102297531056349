import { ReactNode, useEffect, useState } from "react";
import { Animated } from "react-native";

type Props = {
  icon: ReactNode;
  animationOngoing: boolean;
  setAnimationOngoing: Function;
};

const SwipeIcon = ({ icon, setAnimationOngoing, animationOngoing }: Props) => {
  const [opacity, setOpacity] = useState(new Animated.Value(1));

  useEffect(() => {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 700,
        useNativeDriver: false,
      }).start(() => {
        setAnimationOngoing(false);
      });
  }, []);
  return (
    <>
      <Animated.View
        style={{
          opacity: opacity,
        }}
      >
        {icon}
      </Animated.View>
    </>
  );
};

export default SwipeIcon;
