import * as Analytics from "expo-firebase-analytics";
import React, { forwardRef, ReactNode } from "react";
import { KeyboardTypeOptions } from "react-native";
import {
  TextInputMask,
  TextInputMaskOptionProp,
  TextInputMaskTypeProp,
} from "react-native-masked-text";
import { TextInput } from "react-native-paper";
import styles, { PRIMARY_COLOR } from "../assets/styles";

type Props = {
  secureTextEntry?: boolean;
  label: string;
  placeholder?: string;
  value: string | undefined;
  onChangeText: Function;
  onTouchStart?: Function;
  style?: any;
  keyboardType?: KeyboardTypeOptions;
  maxLength?: number;
  showKeyboard?: boolean;
  ref?: any;
  textMaskType?: TextInputMaskTypeProp;
  textMaskOption?: TextInputMaskOptionProp;
  onSubmitEditing?: Function;
  onFocus?: Function;
  onBlur?: Function;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

const SwingerTextInput = forwardRef<any, Props>(
  (
    {
      secureTextEntry = false,
      label,
      placeholder,
      value,
      onChangeText,
      onTouchStart = () => {},
      style,
      keyboardType,
      maxLength,
      showKeyboard = true,
      textMaskType,
      textMaskOption,
      onSubmitEditing,
      onFocus,
      onBlur,
      leftIcon,
      rightIcon,
    }: Props,
    ref
  ) => {
    const handleTextChanges = (value: string) => {
      onChangeText(value);
      const val = secureTextEntry ? "REDACTED" : value;
      Analytics.logEvent("inputTextChanged", { inputName: label, value: val });
    };

    return (
      <TextInput
        placeholder={placeholder}
        label={label}
        value={value}
        onChangeText={(value) => handleTextChanges(value)}
        style={style != null ? style : styles.textInput}
        secureTextEntry={secureTextEntry}
        onTouchStart={() => onTouchStart()}
        onTouchEnd={() => {
          Analytics.logEvent("inputTouched", { inputName: label });
        }}
        mode={"outlined"}
        children={undefined}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        left={leftIcon}
        right={rightIcon}
        onPressOut={undefined}
        autoComplete={undefined}
        keyboardType={keyboardType}
        maxLength={maxLength}
        showSoftInputOnFocus={showKeyboard}
        theme={{
          colors: {
            placeholder: "grey",
            text: "black",
            primary: PRIMARY_COLOR,
          },
        }}
        ref={ref}
        onSubmitEditing={() => onSubmitEditing && onSubmitEditing()}
        render={
          textMaskType
            ? (props) => (
                // @ts-ignore
                <TextInputMask
                  {...props}
                  type={textMaskType}
                  options={textMaskOption}
                  value={value}
                  onChangeText={(text) => {
                    handleTextChanges(text);
                  }}
                />
              )
            : undefined
        }
      />
    );
  }
);

export default SwingerTextInput;
