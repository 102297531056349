import { AccountT } from './../types';
import { rootUrl } from './../services/ApiService';

import { useQuery, UseQueryOptions } from 'react-query'
import {
    AuthContextT,
} from "../types";
import { getAxios } from '../services/ApiService';

export const useProfiles = (authContext: AuthContextT, accountIds: number[], options?: UseQueryOptions<AccountT[]>) => {
    async function getProfiles() {
        const resp = await getAxios(authContext).then((axios) => {
            return axios.post(`${rootUrl}/account/search`, {
              accountIds: accountIds,
            });
          });
          return await resp.data
    }
    return useQuery<AccountT[]>([accountIds], getProfiles, options)
}
