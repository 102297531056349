import React, { useEffect, useState } from "react";
import { Appearance } from 'react-native-appearance';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import styles from "../assets/styles";
import { parseDate } from "../utils/DateUtils";
import SwingerTextInput from "./SwingerTextInput";

type DateTimePickcerProps = {
  setDate: Function;
  date: Date;
  mode: "date" | "time" | "datetime";
  label: string;
  init: boolean;
  minimumDate?: Date;
};

const colorScheme = Appearance.getColorScheme()
const isDarkModeEnabled = colorScheme === 'dark';

export default function DateTimePicker({
  date,
  setDate,
  mode,
  label,
  init,
  minimumDate,
}: DateTimePickcerProps) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateValue, setDateValue] = useState<string>("");

  const handleConfirm = (date: Date) => {
    setDate(date);
    if (mode === "date") {
      setDateValue(date.toLocaleDateString());
    } else {
      setDateValue(parseDate(date));
    }
    setDatePickerOpen(false);
  };

  useEffect(() => {
    if (!init) {
      if (
        mode === "date" &&
        date.toLocaleDateString() !== new Date().toLocaleDateString()
      ) {
        setDateValue(date.toLocaleDateString());
      }
    }
  }, [init]);

  return (
    <>
      <SwingerTextInput
        label={label}
        value={dateValue}
        onChangeText={() => {}}
        onTouchStart={() => setDatePickerOpen(true)}
        style={{ ...styles.textInput, marginTop: 10 }}
        placeholder={mode === "date" ? "MM/DD/YYYY" : ""}
        showKeyboard={false}
      />
        <DateTimePickerModal
          mode={mode}
          date={date}
          isVisible={datePickerOpen}
          isDarkModeEnabled={isDarkModeEnabled}
          minimumDate={minimumDate}
          onConfirm={(date: Date) => {
            handleConfirm(date);
          }}
          onCancel={(_: Date) => {
            setDatePickerOpen(false);
          }}
          onHide={() => {
          }}
          onChange={() => {
          }}
        />
      {/* <TextInputMask
        mask={Masks.DATE_MMDDYYYY}
        options={{
          format: "DD-MM-YYYY HH:mm:ss",
        }}
      /> */}
      {/* <TextInputMask
        type={"datetime"}
        options={{
          format: "YYYY/MM/DD",
        }}
        value={dateValue}
        onChangeText={(text) => {
          handleConfirm(text);
        }}
      /> */}
    </>
  );
}
