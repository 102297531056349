import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import { Button } from "react-native-paper";
import styles, { BLACK, SECONDARY_COLOR, WHITE } from "../assets/styles";
import CachedImage from "../components/CachedImage";
import DateTimePicker from "../components/DateTimePicker";
import GolfCourseSelection from "../components/GolfCourseSelection";
import GolfView from "../components/GolfView";
import SwingerButton from "../components/SwingerButton";
import SwingerTextInput from "../components/SwingerTextInput";
import { useCreateGolfMatch } from "../hooks/useGolfMatches";
import { getContext } from "../services/AuthContext";
import { RootStackParamList } from "../types";

const numbers = [{ num: 1 }, { num: 2 }, { num: 3 }];

const margins = { marginTop: 10 };

type Props = StackScreenProps<RootStackParamList>;

const SetupPlay = ({ navigation }: Props) => {
  const [numPlayers, setNumPlayers] = useState<number>(3);
  const [course, setCourse] = useState<string>("");
  const [teeTime, setTeeTime] = useState<number>(new Date().getTime());
  const [selectedFriends, setSelectedFriends] = useState<Map<number, string>>(
    new Map()
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const authContext = getContext();
  const { accountContext } = authContext;
  const { mutateAsync: createGolfMatch } = useCreateGolfMatch(authContext);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      navigation.setOptions({ headerBackTitle: "Back" });
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  async function onSubmit() {
    if (course === "") {
      setErrorMessage("You must specify a course that you're playing.");
      return;
    }
    await createGolfMatch({
      time: new Date(teeTime),
      courseName: course,
      hostProfileId: accountContext.id!!,
      playersNeeded: numPlayers,
      attendingProfileIds: [
        ...Array.from(selectedFriends.keys()),
        accountContext.id!!,
      ],
    });
    navigation.goBack();
  }

  function onInputCourse(input: string) {
    if (errorMessage) {
      setErrorMessage(undefined);
    }
    setCourse(input);
  }

  return (
    <GolfView
      containerOverride={{ flex: 1 }}
      flex={1}
      enableScrollView={true}
      centerView={false}
    >
      <View
        style={{
          backgroundColor: WHITE,
          borderRadius: 8,
          margin: 10,
          elevation: 1,
          padding: 10,
          shadowOpacity: 0.05,
          shadowRadius: 10,
          shadowColor: BLACK,
          shadowOffset: { height: 0, width: 0 },
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Text style={margins}>How many players are you looking for?</Text>
        <FlatList
          style={{ marginLeft: 50, marginRight: 50, marginTop: 10 }}
          data={numbers}
          horizontal={true}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => {
            const mode = numPlayers === item.num ? "contained" : "outlined";
            return (
              <TouchableOpacity onPress={() => setNumPlayers(item.num)}>
                <Button color={SECONDARY_COLOR} mode={mode}>
                  {item.num}
                </Button>
              </TouchableOpacity>
            );
          }}
        />
        <Text style={margins}>Which course?</Text>
        <GolfCourseSelection courseCallback={onInputCourse} />
        <Text>When is the Tee Time?</Text>
        <DateTimePicker
          label="Tee Time"
          date={new Date(teeTime)}
          setDate={setTeeTime}
          mode="datetime"
          init={true}
          minimumDate={new Date()}
        />
        {selectedFriends.size > 0 && <Text>Invited Friends</Text>}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Array.from(selectedFriends).map(([key, value]) => (
            <CachedImage
              key={key}
              uri={value}
              style={{ ...styles.avatar, marginLeft: 10 }}
            />
          ))}
        </View>
        <SwingerButton
          onPress={() =>
            navigation.navigate("Invite Friends", {
              numPlayersNeeded: numPlayers,
              selectedFriends,
              setSelectedFriends,
            })
          }
        >
          Invite Friends
        </SwingerButton>
        {errorMessage && (
          <Text style={{ color: "red", textAlign: "center" }}>
            {errorMessage}
          </Text>
        )}
        <SwingerButton onPress={() => onSubmit()}>Book it!</SwingerButton>
      </View>
    </GolfView>
  );
};

export default SetupPlay;
