import { AttachStep } from "@stackbuilders/react-native-spotlight-tour";
import React, { ReactNode } from "react";
import { Text, View } from "react-native";
import styles, { DARK_GRAY, PRIMARY_COLOR } from "../assets/styles";
import { TabBarIconT } from "../types";
import Icon from "./Icon";

const TabBarIcon = ({
  focused,
  iconName,
  text,
  iconSize = 16,
  tourGuideStepNum,
  customIcon = false,
}: TabBarIconT) => {
  const iconFocused = focused ? PRIMARY_COLOR : DARK_GRAY;

  const getViewWithMaybeAttachedStep = (children: ReactNode) => {
    if (tourGuideStepNum) {
      return (
        //@ts-ignore
        <AttachStep index={tourGuideStepNum} style={styles.iconMenu}>
          {children}
        </AttachStep>
      );
    } else {
      return <View style={styles.iconMenu}>{children}</View>;
    }
  };

  return (
    <View>
      {getViewWithMaybeAttachedStep(
        <>
          <Icon
            name={iconName}
            size={iconSize}
            color={iconFocused}
            useCustom={customIcon}
          />
          <Text style={[styles.tabButtonText, { color: iconFocused }]}>
            {text}
          </Text>
        </>
      )}
    </View>
  );
};

export default TabBarIcon;
