import { StackNavigationProp } from "@react-navigation/stack";
import { Camera, CameraCapturedPicture } from "expo-camera";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Dimensions,
  FlatList,
  Image,
  ImageURISource,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  apiCreateOrUpdateProfile,
  apiUploadImages,
} from "../services/ApiService";
import { getContext } from "../services/AuthContext";
import { RootStackParamList } from "../types";
import * as ImagePicker from "expo-image-picker";
import OpenCamera from "./OpenCamera";
import GolfView from "./GolfView";
import MastersHeader from "./MastersHeader";
import SwingerButton from "./SwingerButton";
import { PRIMARY_COLOR } from "../assets/styles";
import LoadingSpinner from "./LoadingSpinner";
import { Feather } from "@expo/vector-icons";
import PromptSettingsMessage from "./PromptSettingsMessage";

let imageIdx = 0;

type Props = {
  navigator: StackNavigationProp<RootStackParamList, any>;
  headerText: string;
  buttonText: string;
  noImagesErrorMessage: string;
  onUpdateComplete?: Function;
};

const ImageEdit = ({
  navigator,
  headerText,
  buttonText,
  onUpdateComplete,
  noImagesErrorMessage,
}: Props) => {
  const [imagesToAdd, setImagesToAdd] = useState<Map<number, string>>(
    new Map()
  );
  const [imagesToDelete, setImagesToDelete] = useState<Array<string>>([]);
  const [images, setImages] = useState<Map<number, ImageURISource>>(new Map());
  const [cameraOpen, setCameraOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | undefined>(
    undefined
  );

  const authContext = getContext();
  const { accountContext, setAccount } = authContext;

  const swiperSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (
      accountContext.profile?.images != undefined &&
      accountContext.profile?.images.length > 0
    ) {
      const imageMap: Map<number, ImageURISource> = new Map();
      accountContext.profile!!.images!!.forEach((imageUrl: string) => {
        imageIdx++;
        imageMap.set(imageIdx, { uri: imageUrl });
      });
      setImages(imageMap);
    }
  }, [accountContext]);

  useEffect(() => {
    if (cameraOpen) {
      setErrorMessage(undefined);
      navigator.setOptions({
        headerLeft: () => (
          <Button
            title="Back"
            onPress={() => {
              setCameraOpen(false);
            }}
          />
        ),
      });
    } else {
      navigator.setOptions({
        headerLeft: undefined,
      });
    }
  }, [cameraOpen]);

  const setBase64Image = (base64Image: string) => {
    imageIdx++;
    const imagesToAddTemp = imagesToAdd;
    imagesToAddTemp.set(imageIdx, base64Image);
    setImagesToAdd(new Map(imagesToAddTemp));

    const imagesTemp = images;
    imagesTemp.set(imageIdx, { uri: `data:image/jpeg;base64,${base64Image}` });
    setImages(new Map(imagesTemp));
  };

  const removeImage = (key: number) => {
    // Check if it hasn't been saved and just need to delete it locally
    if (!imagesToAdd.has(key)) {
      const imageToDel = images.get(key);
      setImagesToDelete([...imagesToDelete, imageToDel?.uri!!]);
    } else {
      imagesToAdd.delete(key);
      setImagesToAdd(new Map(imagesToAdd));
    }
    images.delete(key);
    setImages(new Map(images));
  };

  const savePhoto = (capturedPhoto: CameraCapturedPicture) => {
    setCameraOpen(false);
    let base64Str = capturedPhoto.base64!!;
    if(base64Str.indexOf(',') != -1) {
      base64Str = base64Str.split(',')[1]
    }
    console.log(base64Str);
    setBase64Image(base64Str);
  };

  const onClickContinue = () => {
    if (images.size === 0) {
      setErrorMessage(<Text>{noImagesErrorMessage}</Text>);
      return;
    }
    setIsLoading(true);
    apiUploadImages(
      {
        imagesToUpload: Array.from(imagesToAdd.values()),
        imageUrlsToDelete: imagesToDelete,
      },
      authContext
    )
      .then((response) => {
        const profile = response.data as any;
        accountContext.profile = profile;
        accountContext.profile!!.isInitialized = true;
        apiCreateOrUpdateProfile(accountContext.profile!!, authContext).then(
          (resp) => {
            const profile = response.data as any;
            accountContext.profile = profile;
            setAccount({ ...accountContext });
            if (onUpdateComplete) {
              onUpdateComplete();
            }
          }
        );
      })
      .catch((error) => {
        console.error(JSON.stringify(error.response));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const pickImage = async () => {
    setErrorMessage(undefined);
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      setBase64Image(result.base64!!);
    }
  };

  const takePhoto = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();

    if (status === "granted") {
      setCameraOpen(true);
    } else {
      setErrorMessage(
        <>
          <PromptSettingsMessage
            errorMessage={
              "You must grant Swinger permission to the camera to take a photo."
            }
            permissionType={"camera"}
            errorColor={"red"}
          />
        </>
      );
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          {cameraOpen ? (
            <OpenCamera saveCallback={savePhoto} />
          ) : (
            <GolfView enableScrollView={true}>
              <MastersHeader text={headerText} />
              <SwingerButton marginTop={50} width={350} onPress={pickImage}>
                Select an Image
              </SwingerButton>
              <SwingerButton width={350} onPress={takePhoto}>
                Take a Photo
              </SwingerButton>
              <>{errorMessage ? <>{errorMessage}</> : <></>}</>
              <View style={{ flex: 1 / 2, marginTop: 20 }}>
                <FlatList
                  horizontal={true}
                  data={Array.from(images.keys())}
                  renderItem={({ item, index }) => {
                    return (
                      <View
                        style={{
                          // flex: 1,
                          marginLeft: index === 0 ? 50 : 0,
                          marginRight: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row-reverse",
                            marginTop: 10,
                            zIndex: 100,
                          }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              top: -12,
                              left: -8,
                            }}
                          >
                            <TouchableOpacity onPress={() => removeImage(item)}>
                              <Feather
                                name="x-circle"
                                size={24}
                                color={PRIMARY_COLOR}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                        <Image
                          key={item}
                          style={{ width: "95%", height: "95%", minWidth: 200 }}
                          resizeMode="cover"
                          source={images.get(item)!!}
                        />
                      </View>
                    );
                  }}
                  keyExtractor={(item) => item.toString()}
                />
              </View>
              <SwingerButton onPress={() => onClickContinue()}>
                {buttonText}
              </SwingerButton>
            </GolfView>
          )}
        </>
      ) : (
        <>
          <LoadingSpinner isLoading={true} />
        </>
      )}
    </>
  );
};

export default ImageEdit;
