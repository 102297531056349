import React from "react";
import { Platform, View } from "react-native";
import { MAX_DIMENSION_WIDTH } from "../assets/styles";

type Props = {
  children: React.ReactNode;
};

export const WebAspectRatioView = ({ children }: Props) => {
  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={{ flexGrow: 1, backgroundColor: "#484848" }}></View>
        <View style={{ maxWidth: MAX_DIMENSION_WIDTH, width: "100%" }}>
          {children}
        </View>
        <View style={{ flexGrow: 1, backgroundColor: "#484848" }}></View>
      </View>
    );
  } else {
    return <>{ children }</>;
  }
};
