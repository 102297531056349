import { rootUrl } from "./../services/ApiService";

import {
  useQuery,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "react-query";
import { AuthContextT, GolfMatchT, RootStackParamList } from "../types";
import { getAxios } from "../services/ApiService";
import { useEffect } from "react";
import { StackNavigationProp } from "@react-navigation/stack";

export const useGolfMatches = (
  authContext: AuthContextT,
  navigation: StackNavigationProp<RootStackParamList, any>,
  options?: UseQueryOptions<GolfMatchT[]>
) => {
  async function getGolfMatches() {
    const resp = await getAxios(authContext).then((axios) => {
      return axios.get(`${rootUrl}/golf/match/available`);
    });
    return await resp.data;
  }
  return useQuery<GolfMatchT[]>(["golf-matches"], getGolfMatches, options);
};

export const useMyGolfMatches = (
  authContext: AuthContextT,
  navigation: StackNavigationProp<RootStackParamList, any>,
  options?: UseQueryOptions<GolfMatchT[]>
) => {

  async function getMyGolfMatches() {
    const resp = await getAxios(authContext).then((axios) => {
      return axios.get(`${rootUrl}/golf/match`);
    });
    return await resp.data;
  }
  return useQuery<GolfMatchT[]>(["my-golf-matches"], getMyGolfMatches, options);
};

export const useCreateGolfMatch = (authContext: AuthContextT) => {
  const queryClient = useQueryClient();

  async function createGolfMatch(golfMatch: GolfMatchT) {
    const resp = await getAxios(authContext).then((axios) => {
      return axios.post(`${rootUrl}/golf/match`, golfMatch);
    });
    return await resp.data;
  }

  return useMutation(createGolfMatch, {
    onSettled: (golfMatch: any) => {
      queryClient.invalidateQueries(["my-golf-matches"]);
      queryClient.invalidateQueries(["golf-matches"]);
    },
  });
};
export const useUpdateGolfMatch = (authContext: AuthContextT) => {
  const queryClient = useQueryClient();

  async function updateGolfMatch(golfMatch: GolfMatchT) {
    const resp = await getAxios(authContext).then((axios) => {
      return axios.put(`${rootUrl}/golf/match`, golfMatch);
    });
    return await resp.data;
  }

  return useMutation(updateGolfMatch, {
    onSettled: (golfMatch: any) => {
      queryClient.invalidateQueries(["my-golf-matches"]);
      queryClient.invalidateQueries(["golf-matches"]);
    },
  });
};

export const useAttendingMatches = (authContext: AuthContextT) => {
  const queryClient = useQueryClient();
  async function attendGolfMatch(golfMatch: GolfMatchT) {
    const resp = await getAxios(authContext).then(async (axios) => {
      return axios.post(`${rootUrl}/golf/match/attending/true`, golfMatch);
    });
    return await resp.data;
  }

  return useMutation(attendGolfMatch, {
    onSettled: (golfMatch: any) => {
      queryClient.invalidateQueries(["my-golf-matches"]);
      queryClient.invalidateQueries(["golf-matches"]);
    },
  });
};

export const useNotAttendingMatches = (authContext: AuthContextT) => {
  const queryClient = useQueryClient();
  async function unattendGolfMatch(golfMatch: GolfMatchT) {
    const resp = await getAxios(authContext).then(async (axios) => {
      return axios.post(`${rootUrl}/golf/match/attending/false`, golfMatch);
    });
    return await resp.data;
  }

  return useMutation(unattendGolfMatch, {
    onSettled: (golfMatch: any) => {
      queryClient.invalidateQueries(["my-golf-matches"]);
      queryClient.invalidateQueries(["golf-matches"]);
    },
  });
};

export function useInvalidateGolfMatches() {
  const client = useQueryClient();
  return () => {
    client.invalidateQueries(["my-golf-matches"]);
    client.invalidateQueries(["golf-matches"]);
  };
}
