// @ts-ignore
import shorthash from "shorthash";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";

export const getCachedImage = async (uri: string) => {
  if (Platform.OS === "web") {
    return uri;
  }
  try {
    const name = shorthash.unique(uri);
    const path = `${FileSystem.cacheDirectory}${name}`;

    const image = await FileSystem.getInfoAsync(path);
    if (image.exists) {
      return image.uri;
    }

    const newImage = await FileSystem.downloadAsync(uri, path);
    return newImage.uri;
  } catch (e) {
    console.error(`For URI ${uri}`, e);
    return uri;
  }
};
