import { StackScreenProps } from "@react-navigation/stack";
import React from "react";
import ProfileEdit from "../../components/ProfileEdit";
import { RootStackParamList } from "../../types";

type Props = StackScreenProps<RootStackParamList>;

function CreateProfile({ navigation }: Props) {
  return (
    <>
      <ProfileEdit
        headerText="Let's craft your profile"
        onUpdateComplete={() => {
          navigation.navigate("Upload Photos", {});
        }}
        showLegal={true}
        buttonText={"Continue"}
      />
    </>
  );
}

export default CreateProfile;
