import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import styles, { WHITE } from "../assets/styles";
import { Message } from "../components";
import GolfView from "../components/GolfView";
import LoadingSpinner from "../components/LoadingSpinner";
import { getContext } from "../services/AuthContext";
import { getChatContext } from "../services/ChatContext";
import { AccountT, RootStackParamList } from "../types";
import { getChatTitle } from "../utils/ChatUtils";
import { getProfileImageURI } from "../utils/ProfileUtils";

type Props = StackScreenProps<RootStackParamList>;

function Messages({ navigation }: Props) {
  const [offset, setOffset] = useState<number>(0);
  const [isLoadingg, setIsLoadingg] = useState<boolean>(false);
  const authContext = getContext();
  const { accountContext } = authContext;
  const { threadMap, refreshChats, getAccounts } = getChatContext();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (threadMap.size === 0) {
        setIsLoadingg(true);
      }
      refreshChats(0).then(() => {
        setIsLoadingg(false);
      });
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  function openChat(chatId: number) {
    navigation.navigate("Chat", { chatId });
  }

  return (
    <>
      {!isLoadingg ? (
        <GolfView
          flex={1}
          disableKeyboardOverride={true}
          containerOverride={{
            ...styles.containerMessages,
            backgroundColor: WHITE,
            flexGrow: 1,
          }}
        >
          {threadMap.size > 0 ? (
            <FlatList
              data={Array.from(threadMap.values()).sort(
                (a, b) =>
                  new Date(b.lastMessageTs).getTime() -
                  new Date(a.lastMessageTs).getTime()
              )}
              onEndReachedThreshold={0.3}
              ListFooterComponent={() => {
                if (threadMap.size > 29) {
                  return <LoadingSpinner marginTop={20} isLoading={true} />;
                }
                return <></>;
              }}
              onEndReached={() => {
                const newOffset = offset + 30;
                if (threadMap.size === newOffset) {
                  setOffset(newOffset);
                  refreshChats(newOffset);
                }
              }}
              keyExtractor={(item, index) => item.id.toString()}
              renderItem={({ item }) => {

                const messagesLength =
                  item.messages != null ? item.messages.length : 0;
                return (
                  <TouchableOpacity
                    onPress={() => {
                      openChat(item.id!!);
                    }}
                  >
                    <Message
                      images={item.accountIds
                        .filter((accountId) => accountId != accountContext.id)
                        .map(
                          (accountId) =>
                          getProfileImageURI(getAccounts([accountId])[0].profile)
                        )}
                      name={getChatTitle(
                        item,
                        accountContext,
                        getAccounts(item.accountIds)
                      )}
                      lastMessage={
                        messagesLength > 0
                          ? item.messages[0].message
                          : undefined
                      }
                      unread={item.unread ?? true}
                      isMatch={item.title != undefined}
                    />
                  </TouchableOpacity>
                );
              }}
            />
          ) : (
            <View
              style={{
                marginTop: "10%",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "90%",
              }}
            >
              <Text style={styles.infoMessage}>This seems empty...</Text>
              <Text style={styles.infoMessage}>
                Swing to find new golf buddies!
              </Text>
            </View>
          )}
        </GolfView>
      ) : (
        <>
          <LoadingSpinner isLoading={true} />
        </>
      )}
    </>
  );
}

export default Messages;
