import React from "react";
import { ActivityIndicator, View } from "react-native";
import { SECONDARY_COLOR } from "../assets/styles";

type Props = { isLoading: boolean, marginTop?: number };

const LoadingSpinner = ({isLoading, marginTop = 0}: Props) => {
  return (
    <>
      {isLoading ? (
        <View
          style={{
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            marginTop,
          }}
        >
          <ActivityIndicator size={'small'} color={SECONDARY_COLOR} />
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingSpinner;
