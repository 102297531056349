import * as Analytics from "expo-firebase-analytics";
import React from "react";
import { Text, ViewStyle } from "react-native";
import { Button } from "react-native-paper";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../assets/styles";

type Props = {
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  onPress: Function;
  children: React.ReactNode;
  disabled?: boolean;
  iconName?: string;
  uppercase?: boolean;
  backgroundColor?: string;
  height?: number;
  color?: string;
  iconSize?: number;
  mode?: "text" | "outlined" | "contained" | undefined;
  buttonName?: string;
};

const SwingerButton = ({
  marginBottom = 5,
  marginTop = 5,
  width,
  onPress,
  children,
  iconName,
  height,
  disabled = false,
  uppercase = true,
  backgroundColor = SECONDARY_COLOR,
  color = PRIMARY_COLOR,
  mode = "outlined",
  iconSize,
  buttonName,
}: Props) => {
  const style: ViewStyle = {
    marginBottom: marginBottom,
    marginTop: marginTop,
    alignSelf: "center",
    width: width ?? 200,
    backgroundColor: backgroundColor,
    height: height,
    borderRadius: 5,
  };

  const handleOnPress = () => {
    onPress();
    try {
      Analytics.logEvent("buttonClick", {
        buttonText: buttonName ?? children,
        value: buttonName ?? children,
      });
    } catch (e: any) {
      console.error("Error button analytics", e);
    }
  };

  return (
    <Button
      disabled={disabled}
      style={style}
      color={color}
      onPress={() => handleOnPress()}
      icon={iconName}
      compact={true}
      uppercase={uppercase}
      labelStyle={{ fontSize: iconSize }}
      mode={mode}
    >
      <Text style={{ fontSize: 14 }}>{children}</Text>
    </Button>
  );
};

export default SwingerButton;
