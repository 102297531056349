import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { Text } from "react-native";
import GolfMatchList from "../components/GolfMatchList";
import SwingerButton from "../components/SwingerButton";
import { useAttendingMatches, useGolfMatches } from "../hooks/useGolfMatches";
import { useProfiles } from "../hooks/useProfiles";
import { getContext } from "../services/AuthContext";
import { GolfMatchT, RootStackParamList } from "../types";
import { getMatchesWithProfiles } from "../utils/GolfMatchUtil";

type Props = StackScreenProps<RootStackParamList>;

const PlayNow = ({ navigation }: Props) => {
  const [availableMatches, setAvailableMatches] = useState<GolfMatchT[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<GolfMatchT>();
  const [matchIdToProfiles, setMatchIdToProfiles] = useState<any>({});

  const authContext = getContext();
  const { mutateAsync: attendGolfMatch } = useAttendingMatches(authContext);
  const { data: golfMatches, isLoading: golfMatchesLoading } =
    useGolfMatches(authContext, navigation);
  const { data: profiles, isLoading: profilesLoading } = useProfiles(
    authContext,
    golfMatches ? golfMatches!.map((gm) => gm.attendingProfileIds!).flat() : [],
    { enabled: !golfMatchesLoading }
  );

  useEffect(() => {
    if (
      !golfMatchesLoading &&
      !profilesLoading &&
      golfMatches != undefined &&
      profiles != undefined
    ) {
      const [tempMatches, mapProfileMap] = getMatchesWithProfiles(
        authContext,
        golfMatches,
        profiles
      );
      setAvailableMatches(tempMatches);
      setMatchIdToProfiles(mapProfileMap);
    }
  }, [golfMatchesLoading, profilesLoading, golfMatches, profiles]);

  async function onCountMeIn(item: GolfMatchT) {
    await attendGolfMatch(item);
    setModalVisible(false);
    setSelectedMatch(undefined);
  }

  return (
    <>
      <GolfMatchList
        matchIdToProfiles={matchIdToProfiles}
        isLoading={golfMatchesLoading || profilesLoading}
        navigation={navigation}
        title="Find Matches"
        modal={
          <>
            <Text>
              Do you want to let {selectedMatch?.hostProfile!!.profile?.name}{" "}
              know your in?
            </Text>
            <SwingerButton
              onPress={() =>
                selectedMatch != undefined ? onCountMeIn(selectedMatch) : ""
              }
            >
              Count me in!
            </SwingerButton>
          </>
        }
        matches={availableMatches}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setSelectedMatch={setSelectedMatch}
      />
    </>
  );
};

export default PlayNow;
