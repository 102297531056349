import * as Device from "expo-device";
import * as Analytics from "expo-firebase-analytics";
import { Subscription } from "expo-modules-core";
import * as Notifications from "expo-notifications";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Platform } from "react-native";
import { apiCreateOrUpdateProfile } from "../services/ApiService";
import { AuthContextT } from "../types";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});

export default function usePushNotifications(setPendingRouteChanges: Function) {
  const [notification, setNotification] = useState<
    Notifications.Notification | undefined
  >(undefined);
  const [checkedPermissions, setCheckedPermissions] = useState<boolean>(false);
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();
  // const lastNotificationResponse = Notifications.useLastNotificationResponse();

  async function checkPermissions(
    routeName: string,
    authContext?: AuthContextT
  ) {
    if(Platform.OS === 'web') {
      return;
    }
    if (
      !checkedPermissions &&
      authContext != undefined &&
      authContext != null &&
      (routeName == "Play Now" || routeName == "Messages")
    ) {
      const accountContext = authContext!!.accountContext;
      if (
        accountContext != null &&
        accountContext.profile &&
        accountContext.profile.pushToken == null
      ) {
        setCheckedPermissions(true);
        registerForPushNotificationsAsync().then((token) => {
          if (token) {
            const profile = accountContext.profile!!;
            profile.pushToken = token;
            apiCreateOrUpdateProfile(profile, authContext!!);
          }
        });
      }
    }
  }

  useEffect(() => {
    if(Platform.OS === 'web') {
      return;
    }
    //We load this before initialization. We need to wait for this callback to become available
    if (setPendingRouteChanges) {
      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
        });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          const map = new Map();
          map.set(
            response.notification.request.content.data.routeName,
            response.notification.request.content.data.params
          );
          setPendingRouteChanges(map);
        });

      return () => {
        if (notificationListener.current) {
          Notifications.removeNotificationSubscription(
            notificationListener.current
          );
        }
        if (responseListener.current) {
          Notifications.removeNotificationSubscription(
            responseListener.current
          );
        }
      };
    }
  }, [setPendingRouteChanges]);

  async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        Analytics.logEvent("DeniedPermission", {
          permission: "pushNotifications",
        });
        console.error("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      console.log("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  }

  return [checkPermissions];
}
