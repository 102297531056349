import { StackScreenProps } from "@react-navigation/stack";
import { format } from "prettier";
// @ts-ignore
import React, { useEffect, useState } from "react";
import { FlatList, Image, Text, View, TouchableOpacity } from "react-native";
import styles, { LIGHT_GRAY, RED } from "../assets/styles";
import { Icon } from "../components";
import CachedImage from "../components/CachedImage";
import GolfMatchList from "../components/GolfMatchList";
import SwingerButton from "../components/SwingerButton";
import {
  useMyGolfMatches,
  useNotAttendingMatches,
  useUpdateGolfMatch,
} from "../hooks/useGolfMatches";
import { useProfiles } from "../hooks/useProfiles";
import { getContext } from "../services/AuthContext";
import { AccountT, GolfMatchT, RootStackParamList } from "../types";
import {
  getMatchesWithProfiles,
  getProfileIdsFromGolfMatches,
} from "../utils/GolfMatchUtil";
import { getProfileImageURI } from "../utils/ProfileUtils";

type Props = StackScreenProps<RootStackParamList>;

const YourRounds = ({ navigation }: Props) => {
  const [myMatches, setMyMatches] = useState<GolfMatchT[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<GolfMatchT | undefined>(
    undefined
  );
  const [matchIdToProfiles, setMatchIdToProfiles] = useState<any>({});
  const [selectedFriends, setSelectedFriends] = useState<Map<number, string>>(
    new Map()
  );

  const authContext = getContext();
  const { accountContext } = authContext;

  const { mutateAsync: unattendGolfMatch } =
    useNotAttendingMatches(authContext);
  const { mutateAsync: updateGolfMatch } = useUpdateGolfMatch(authContext);
  const { data: myGolfMatches, isLoading: golfMatchesLoading } =
    useMyGolfMatches(authContext, navigation);
  const { data: profiles, isLoading: profilesLoading } = useProfiles(
    authContext,
    getProfileIdsFromGolfMatches(myGolfMatches!!),
    {
      enabled: !golfMatchesLoading && myGolfMatches && myGolfMatches.length > 0,
    }
  );

  const resetState = () => {
    setSelectedMatch(undefined);
    setModalVisible(false);
  };

  const updateSelectedMatchAndState = async (updatedMatch: GolfMatchT) => {
    if (selectedMatch) {
      await updateGolfMatch(updatedMatch);
      setSelectedMatch({ ...updatedMatch });
      setSelectedFriends(new Map());
    }
  };

  useEffect(() => {
    if (!profilesLoading && !golfMatchesLoading && profiles && myGolfMatches) {
      const [tempMatches, mapProfileMap] = getMatchesWithProfiles(
        authContext,
        myGolfMatches,
        profiles
      );
      setMyMatches(tempMatches);
      setMatchIdToProfiles(mapProfileMap);
    }
  }, [profilesLoading, golfMatchesLoading, myGolfMatches, profiles]);

  useEffect(() => {
    if (selectedMatch != undefined && selectedFriends.size > 0) {
      setModalVisible(true);
      const attending = new Set([
        ...selectedMatch.attendingProfileIds!!,
        ...Array.from(selectedFriends.keys()),
      ]);
      updateSelectedMatchAndState({
        ...selectedMatch,
        attendingProfileIds: [...Array.from(attending)],
      });
    }
  }, [selectedFriends]);

  async function cancelMatch(golfMatch: GolfMatchT) {
    golfMatch.cancelled = true;
    await updateGolfMatch(golfMatch);
    resetState();
    setMyMatches(myMatches.filter((match) => match.id !== golfMatch.id));
  }

  async function leaveMatch(golfMatch: GolfMatchT) {
    await unattendGolfMatch(golfMatch);
    resetState();
  }

  async function removePlayer(player: AccountT) {
    if (selectedMatch != undefined) {
      updateSelectedMatchAndState({
        ...selectedMatch,
        attendingProfileIds: selectedMatch.attendingProfileIds?.filter(
          (pId) => pId !== player.id
        ),
      });
    }
  }

  return (
    <GolfMatchList
      matchIdToProfiles={matchIdToProfiles}
      navigation={navigation}
      showCreateMatch={true}
      title="Your Rounds"
      isLoading={profilesLoading || golfMatchesLoading}
      myView={true}
      modal={
        <View style={{ width: "90%" }}>
          <Text style={{ marginBottom: "5%" }}>Attendees</Text>
          <FlatList
            numColumns={1}
            data={matchIdToProfiles[selectedMatch?.id!!]}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CachedImage
                  key={item!!.id}
                  uri={getProfileImageURI(item!!.profile)}
                  style={styles.avatarSmall}
                />
                <Text style={{ marginRight: 10 }}>{item.profile?.name}</Text>
                {selectedMatch?.hostProfileId === accountContext.id ? (
                  <>
                    {item.id !== accountContext.id!! ? (
                      <TouchableOpacity onPress={() => removePlayer(item)}>
                        <Icon
                          name="remove-circle-outline"
                          color={RED}
                          size={20}
                        />
                      </TouchableOpacity>
                    ) : (
                      <Icon
                        name="remove-circle-outline"
                        color={LIGHT_GRAY}
                        size={20}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </View>
            )}
          />
          <SwingerButton
            onPress={() => {
              if (selectedMatch != undefined) {
                setModalVisible(false);
                const numPlayersNeeded =
                  selectedMatch.playersNeeded -
                  (selectedMatch.attendingProfileIds!!.length - 1);
                navigation.navigate("Invite Friends", {
                  numPlayersNeeded: numPlayersNeeded,
                  alreadyAttendingFriends: new Set(
                    selectedMatch.attendingProfileIds
                  ),
                  selectedFriends,
                  setSelectedFriends,
                });
              }
            }}
          >
            Invite Friends
          </SwingerButton>
          {selectedMatch?.hostProfileId === accountContext.id ? (
            <SwingerButton
              onPress={() =>
                selectedMatch != undefined ? cancelMatch(selectedMatch) : ""
              }
            >
              Cancel Match
            </SwingerButton>
          ) : (
            <SwingerButton
              onPress={() =>
                selectedMatch != undefined ? leaveMatch(selectedMatch) : ""
              }
            >
              Leave Match
            </SwingerButton>
          )}
        </View>
      }
      matches={myMatches}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      setSelectedMatch={setSelectedMatch}
    />
  );
};

export default YourRounds;
