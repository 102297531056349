import { Entypo } from "@expo/vector-icons";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import FriendList from "../../components/FriendList";
import { apiGetAllBlockedAccounts } from "../../services/ApiService";
import { getContext } from "../../services/AuthContext";
import { AccountT, RootStackParamList } from "../../types";

type Props = StackScreenProps<RootStackParamList, "Blocked Users">;

const BlockedUsers = ({ route, navigation }: Props) => {
  const [blockedAccounts, setBlockedAccounts] = useState<Array<AccountT>>([]);

  const authContext = getContext();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      // TODO Add these to the account map
      apiGetAllBlockedAccounts(authContext).then((resp) => {
        setBlockedAccounts(resp.data);
      });
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  const handleClickUser = (account: AccountT) => {
    navigation.navigate("User Info", {
      accountId: account.id!!,
      isBlocked: true,
    });
  };

  return (
    <>
      {blockedAccounts.length > 0 && (
        <FriendList
          friends={blockedAccounts}
          onClickUser={handleClickUser}
          rightComponent={
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Entypo name="chevron-small-right" size={24} color="black" />
            </View>
          }
        />
      )}
    </>
  );
};

export default BlockedUsers;
